import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import ja from './locales/ja/translation.json';
import es from './locales/es/translation.json';
import de from './locales/de/translation.json';
import it from './locales/it/translation.json';
import fr from './locales/fr/translation.json';
import zh from './locales/zh/translation.json';
import detector from 'i18next-browser-languagedetector';

// configure localization for react-datepicker
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import jaLocale from 'date-fns/locale/ja';
import esLocale from 'date-fns/locale/es';
import deLocale from 'date-fns/locale/de';
import itLocale from 'date-fns/locale/it';
import frLocale from 'date-fns/locale/fr';
import zhLocale from 'date-fns/locale/zh-CN';
import { format, isValid, parse } from 'date-fns';

// register locales for react-datepicker
const dateFnsLocales: { [id: string]: Locale } = {
    ja: jaLocale,
    es: esLocale,
    de: deLocale,
    it: itLocale,
    fr: frLocale,
    'zh-CN': zhLocale,
};

Object.keys(dateFnsLocales).forEach((key) => {
    registerLocale(key, dateFnsLocales[key]);
});

const options = {
    order: ['navigator', 'querystring', 'cookie', 'localStorage'],
    lookupQuerystring: 'lang',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLang',
    caches: ['cookie', 'localStorage'],
    cookieOptions: { path: '/', sameSite: true },
};

i18next
    .use(detector)
    .use(initReactI18next)
    .init({
        detection: options,
        debug: true,
        ns: ['translation'],
        defaultNS: 'translation',
        returnEmptyString: false,
        nsSeparator: '::',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        nonExplicitSupportedLngs: true, //support language variation
        resources: {
            en: {
                translation: en,
            },
            es: {
                translation: es,
            },
            de: {
                translation: de,
            },
            fr: {
                translation: fr,
            },
            it: {
                translation: it,
            },
            ja: {
                translation: ja,
            },
            zh: {
                translation: zh,
            },
        },
    });

// set default locale for react-datepicker
let localeName = i18next.language;
if (localeName.length > 2) localeName = localeName.substring(0, 2);
if (localeName === 'zh') localeName = 'zh-CN';

setDefaultLocale(localeName);

// converts the date to formatted date string in the given format using the current locale.
export const formatDateToLocale = (date: Date, formatStr = 'Ppp') => {
    return format(date, formatStr, {
        locale: dateFnsLocales[localeName],
    });
};

// converts the date to ISO 8601 format (local time zone is UTC).
export const formatDateToISO = (date: Date) => {
    return date.toISOString();
};

// converts the locale  date to ISO 8601 format (local time zone is UTC).
export const formatLocaleDateToISO = (localeString: string | undefined) => {
    if (localeString) {
        const date = localeToDate(localeString);
        if (isValid(date)) return formatDateToISO(date);
    }
    return localeString || '';
};

// parses a locale date string and returns the date object.
export const localeToDate = (localeString: string | undefined) => {
    if (localeString) {
        const result = parse(localeString, 'Ppp', new Date(), {
            locale: dateFnsLocales[localeName],
        });
        if (result && isValid(result)) return result;
    }
    return new Date(localeString || '');
};

export const i18n = i18next;
