import { useState } from 'react';
import { CustomCheckbox } from './custom-checkbox';
import { CustomTextArea } from './custom-text-area';

export type CustomAddHelpTextProps = {
    checkboxVariable: string;
    checkboxLabel: string;
    checkboxDefaultValue: boolean;
    onCheckboxChange: (variable: string, value: boolean) => void;
    txtVariable: string;
    txtLabel: string;
    txtDefaultValue: string;
    className?: string;
    onTxtChange: (variable: string, value: string) => void;
};

export const CustomAddHelpText = ({
    checkboxVariable,
    checkboxLabel,
    checkboxDefaultValue,
    onCheckboxChange,
    txtVariable,
    txtLabel,
    txtDefaultValue,
    className = '',
    onTxtChange,
}: CustomAddHelpTextProps) => {
    const [checkboxValue, setCheckboxValue] = useState(checkboxDefaultValue);
    const [txtValue, setTxtValue] = useState(txtDefaultValue);

    const onCustomCheckboxChange = (variable: string, value: boolean) => {
        setCheckboxValue(value);
        onCheckboxChange(variable, value);
    };

    const onTextAreaChange = (variable: string, value: string) => {
        setTxtValue(value);
        onTxtChange(variable, value);
    };

    return (
        <div className={`flex flex-col ${className}`}>
            <CustomCheckbox
                variable={checkboxVariable}
                label={checkboxLabel}
                defaultValue={checkboxValue}
                onChange={onCustomCheckboxChange}
            />

            {checkboxValue && (
                <CustomTextArea
                    variable={txtVariable}
                    label={txtLabel}
                    defaultValue={txtValue}
                    maxLength={500}
                    rows={3}
                    onChange={onTextAreaChange}
                />
            )}
        </div>
    );
};
