import { useRef, useState } from 'react';
import { FormFieldComponentProps } from 'contracts/forms';
import {
    FormFieldDropdownConfig,
    FormFieldDropdownOption,
    defaultFormFieldDropdownConfig,
} from './form-field-dropdown-config';

export const useFormFieldDropdown = ({ field, updateField }: FormFieldComponentProps) => {
    const configFromField = field.config as FormFieldDropdownConfig;
    const config = {
        ...defaultFormFieldDropdownConfig,
        ...configFromField,
    };
    const configRef = useRef<FormFieldDropdownConfig>(config);
    const [listOptions, setListOptions] = useState(config.options);
    const [multiSelect, setMultiSelect] = useState(config.multiSelect);

    const onMultiSelectChange = (variable: string, value: boolean) => {
        onCustomCheckboxChange(variable, value);
        if (!value) {
            const options: FormFieldDropdownOption[] = JSON.parse(JSON.stringify(listOptions));
            let isDefaultOptionFound = false;
            options.forEach((opt) => {
                if (opt.isDefault) {
                    if (isDefaultOptionFound) opt.isDefault = false;
                    isDefaultOptionFound = true;
                }
            });
            onDropdownOptionsChange(options);
        }
        setMultiSelect(value);
    };

    const onCustomTextFieldChange = (variable: string, value: string) => {
        updateConfig(variable as keyof FormFieldDropdownConfig, value);
    };

    const onCustomCheckboxChange = (variable: string, value: boolean) => {
        updateConfig(variable as keyof FormFieldDropdownConfig, value);
    };

    const onDropdownOptionsChange = (value: FormFieldDropdownOption[]) => {
        updateConfig('options' as keyof FormFieldDropdownConfig, value);
        setListOptions(value);
    };

    const updateConfig = <K extends keyof FormFieldDropdownConfig>(key: K, value: FormFieldDropdownConfig[K]) => {
        const updatedConfig = {
            ...configRef.current,
        };
        updatedConfig[key] = value;
        configRef.current = updatedConfig;

        const modifiedField = {
            ...field,
        };
        modifiedField.config = updatedConfig;
        updateField(modifiedField);
    };

    return {
        config,
        listOptions,
        multiSelect,
        onCustomTextFieldChange,
        onCustomCheckboxChange,
        onDropdownOptionsChange,
        onMultiSelectChange,
    } as const;
};
