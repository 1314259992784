import { useRef, useState } from 'react';
import { TextField } from '@mui/material';
import editIcon from './assets/icon-edit.svg';
import { useFormTitle } from 'custom-hooks/use-form-title';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { CustomIconButton } from 'components/custom-controls';
import { useTranslation } from 'react-i18next';

export const FormTitle = () => {
    const { setFormTitle } = useFormActions();
    const formTitle = useFormTitle();
    const [controlValue, setControlValue] = useState(formTitle);
    const titleInput = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    if (controlValue !== formTitle) setControlValue(formTitle);

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormTitle(event.target.value);
    };

    const onEditButtonClick = () => {
        titleInput?.current?.focus();
        titleInput?.current?.select();
    };

    return (
        <div className="flex flex-row ml-4 mr-1">
            <TextField
                value={controlValue}
                variant="outlined"
                sx={{
                    paddingTop: 0,
                    paddingLeft: 0,
                    border: 'none',
                    '& fieldset': { border: 'none' },
                    '& .MuiOutlinedInput-input': { paddingLeft: 0, paddingRight: 0, paddingBottom: 0, paddingTop: 0.5 },
                }}
                margin="normal"
                size="small"
                placeholder={t('Form Name')}
                inputProps={{ 'aria-label': t('Form Name') }}
                className="flex-1 text-sm font-semibold mt-1"
                inputRef={titleInput}
                onChange={onTitleChange}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value.toString();
                    if (value.length > 100) event.target.value = value.slice(0, 100);
                }}
            />
            <CustomIconButton src={editIcon} tooltip={t('Edit form name')} onClick={onEditButtonClick} />
        </div>
    );
};
