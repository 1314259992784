import { FormFieldCheckboxProvider } from 'components/form-builder-plugins/form-field-checkbox';
import { FormFieldDateProvider } from 'components/form-builder-plugins/form-field-date';
import { FormFieldDropdownProvider } from 'components/form-builder-plugins/form-field-dropdown';
import { FormFieldHiddenProvider } from 'components/form-builder-plugins/form-field-hidden';
import { FormFieldLabelProvider } from 'components/form-builder-plugins/form-field-label';
import { FormFieldNumberProvider } from 'components/form-builder-plugins/form-field-number';
import { FormFieldPageBreakProvider } from 'components/form-builder-plugins/form-field-pagebreak';
import { FormFieldTextProvider } from 'components/form-builder-plugins/form-field-text';
import { FormFieldTextAreaProvider } from 'components/form-builder-plugins/form-field-textarea';
import { FormFieldDropZoneProvider } from 'components/form-builder-plugins/form-field-dropzone';

export const PLUGIN_PROVIDERS = [
    FormFieldTextProvider,
    FormFieldTextAreaProvider,
    FormFieldNumberProvider,
    FormFieldCheckboxProvider,
    FormFieldDropdownProvider,
    FormFieldDateProvider,
    FormFieldHiddenProvider,
    FormFieldLabelProvider,
    FormFieldPageBreakProvider,
    FormFieldDropZoneProvider,
];
