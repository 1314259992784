import { useContext } from 'react';
import { DropdownOptionsEditorCallbacksContext } from './dropdown-options-editor-context-provider';

export const useDropdownOptionsEditorCallbacks = () => {
    const context = useContext(DropdownOptionsEditorCallbacksContext);

    if (!context) {
        throw new Error(
            'useDropdownOptionsEditorCallbacks has to be used within <DropdownOptionsEditorCallbacksContext.Provider>',
        );
    }
    return context;
};
