import { List } from '@mui/material';
import { FormErrorListItem } from './';

type FormErrorListItemsWrapperProps = {
    id?: string;
    items: string[];
};

export const FormErrorListItemsWrapper = ({ id, items }: FormErrorListItemsWrapperProps) => {
    const errorListItems = items.map((item, index) => <FormErrorListItem id={id} title={item} key={index} />);

    return (
        <List component="div" dense={true} disablePadding>
            {errorListItems}
        </List>
    );
};
