import { Paper, Typography } from '@mui/material';
import { useFormErrors } from 'custom-hooks/use-form-errors';
import { CustomAccordion } from 'components/custom-controls';
import { FormErrorListItemsWrapper, FormErrorsCloseButton } from './';
import { useTranslation } from 'react-i18next';

export const FormErrorsPanel = () => {
    const formErrors = useFormErrors();
    const { t } = useTranslation();

    if (!formErrors.show) return <></>;

    const errorListGroups = formErrors.groups.map((g, index) => {
        return (
            <CustomAccordion
                key={index}
                title={g.title}
                titleIcon={g.icon}
                expanded={true}
                className="mb-0.5"
                titleClassName="bg-stc-50"
                detailsClassName="p-0 m-0">
                <FormErrorListItemsWrapper id={g.id} items={g.items} />
            </CustomAccordion>
        );
    });

    return (
        <Paper id="form-errors-panel" elevation={3} className="flex ml-4 mr-4 mt-1 p-0 overflow-y-hidden">
            <div className="flex-1 flex-col">
                <div className="flex flex-row bg-stc-950">
                    <Typography variant="subtitle2" className="flex-1 ml-2.5 mt-2 font-semibold text-white">
                        {t('Error List')}
                    </Typography>
                    <FormErrorsCloseButton />
                </div>
                <div className="flex flex-col h-64 overflow-y-auto">{errorListGroups}</div>
            </div>
        </Paper>
    );
};

export * from './form-error-list-item';
export * from './form-error-list-items-wrapper';
export * from './form-errors-close-button';
