import { Base64 } from 'js-base64';
import { useLocation } from 'react-router-dom';

export const useAppArgs = () => {
    const { search } = useLocation();
    let formId = '',
        groupId = '',
        tenantName = '',
        redirectUrl = '',
        tenantUrl = '',
        tenantSlug = '',
        domain = '';
    const hostUrl = window.location.origin;

    if (search) {
        const decodedQs = Base64.decode(decodeURIComponent(search.replace('?', '')));
        const queryParams = new URLSearchParams(decodedQs);
        tenantName = queryParams.get('tenant') || '';
        formId = queryParams.get('id') || '';
        groupId = queryParams.get('group') || '';
        redirectUrl = queryParams.get('redirect_url') || '';

        const { host } = new URL(redirectUrl);
        const tokens = host.split('.');
        domain = `${tokens[tokens.length - 2]}.${tokens[tokens.length - 1]}`;
        tenantSlug = tokens.length > 3 ? tokens[0] : tenantName;
        tenantUrl = host;
    }

    return {
        formId,
        groupId,
        redirectUrl,
        tenantSlug,
        hostUrl,
        tenantUrl,
        domain,
        search,
    } as const;
};
