import { FormContext } from 'context-providers/form-context-provider';
import { useContext } from 'react';

export const useForm = () => {
    const context = useContext(FormContext);

    if (!context) {
        throw new Error('useFrom has to be used within <FormContext.Provider>');
    }

    return context;
};
