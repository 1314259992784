import { useContext } from 'react';
import { FormPluginsDraggableFieldIdsContext } from 'context-providers/form-plugins-context-provider';

export const usePluginsDraggableFieldIds = () => {
    const context = useContext(FormPluginsDraggableFieldIdsContext);

    if (!context) {
        throw new Error(
            'usePluginsDraggableFieldIds has to be used within <FormPluginsDraggableFieldIdsContext.Provider>',
        );
    }

    return context;
};
