import { FormFieldIcon } from 'components/form-fields-panel';
import { usePlugins } from 'custom-hooks/use-plugins/use-plugins';

type DragOverlayFormFieldProps = {
    fieldType: string;
};

export enum DragSourceTypes {
    FIELDS_PANEL = 'fields_panel',
    FIELDS_PREVIEW = 'fields_preview',
}

export type DragSourceData = {
    type: string;
    dragSource: DragSourceTypes.FIELDS_PANEL;
};

export const DragOverlayFormField = ({ fieldType }: DragOverlayFormFieldProps) => {
    const { getProvider } = usePlugins();
    const prvoder = getProvider(fieldType);

    return (
        <div>
            <FormFieldIcon icon={prvoder?.icon || ''} title={prvoder?.title || ''} dropState />
        </div>
    );
};
