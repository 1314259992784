import React from 'react';

type CustomDialogProps = {
    show: boolean;
    children: React.ReactNode;
};

export const CustomDialog = ({ show, children }: CustomDialogProps) => {
    if (!show) {
        return <></>;
    }

    return (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-slate-800 bg-opacity-50 ">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {children}
                </div>
            </div>
        </div>
    );
};
