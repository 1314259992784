import { FormDetails, FormResponse } from 'contracts/forms';
import { useAppArgs } from 'custom-hooks/use-app-args';
import { useHttp } from 'custom-hooks/use-http';
import { useTranslation } from 'react-i18next';

export const useHttpLoadForm = () => {
    const { baseUrl, httpClient, parseHttpFormErrors } = useHttp();
    const { formId } = useAppArgs();
    const { t } = useTranslation();

    const loadForm = async (): Promise<FormResponse> => {
        if (!formId) {
            return { data: { title: t('Form Name') } as FormDetails };
        }

        const url = `${baseUrl}/forms/${formId}`;
        try {
            const response = await httpClient.get(url);
            const formDetails = response.data as FormDetails;

            if (
                formDetails &&
                formDetails.formDefinition &&
                formDetails.formDefinition.fields &&
                formDetails.formDefinition.fields.length > 0
            ) {
                const pages = formDetails.formDefinition.fields.filter((field) => field.type === 'pagebreak');
                const totalPages = pages.length;
                let pageNumber = 0;
                formDetails.formDefinition.fields.forEach((f, index) => {
                    if (f.type === 'pagebreak') {
                        ++pageNumber;
                        if (formDetails && formDetails.formDefinition && formDetails.formDefinition.fields) {
                            formDetails.formDefinition.fields[index] = {
                                ...f,
                                ...{ pageNumber, totalPages },
                            };
                        }
                    }
                });
            }

            return { data: formDetails };
        } catch (error) {
            return parseHttpFormErrors(error);
        }
    };

    return {
        loadForm,
    } as const;
};
