import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldUnknownProperties } from './form-field-unknown-properties';
import { FormFieldUnknownPreview } from './form-field-unknown-preview';
import unknownImage from './assets/icon-form-field-unknown.svg';

export const FormFieldUnknownProvider: FormFieldProvider = {
    type: 'unknown',
    title: 'Unknown',
    category: 'standard',
    icon: unknownImage,
    previewComponent: FormFieldUnknownPreview,
    propertiesComponent: FormFieldUnknownProperties,
    getField: () => {
        return {
            type: 'unknown',
            id: nanoid(),
            config: {},
        };
    },
    cloneField: (field: FormField) => {
        return field;
    },
    validate: () => {
        return { valid: true };
    },
    getVariable: () => {
        return '';
    },
    getMatadataVariable: () => {
        return '';
    },
    getFormattedValue: () => {
        return null;
    },
};
