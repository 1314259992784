import { Link } from '@mui/material';
import { useAppArgs } from 'custom-hooks/use-app-args';
import { useTranslation } from 'react-i18next';

export const EulaLink = () => {
    const { domain } = useAppArgs();
    const { t } = useTranslation();
    return (
        <Link href={`https://${domain}/eula`} underline="hover" target="_blank" rel="noreferrer" className="text-black">
            {t('EULA')}
        </Link>
    );
};
