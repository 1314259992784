import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldHiddenPreview } from './form-field-hidden-preview';
import { FormFieldHiddenProperties } from './form-field-hidden-properties';
import { FormFieldHiddenConfig, defaultFormFieldHiddenConfig } from './form-field-hidden-config';
import { i18n } from 'i18n';
import hiddenImage from './assets/icon-form-field-password.svg';

export const FormFieldHiddenProvider: FormFieldProvider = {
    type: 'hidden',
    title: i18n.t('Hidden'),
    category: 'standard',
    icon: hiddenImage,
    previewComponent: FormFieldHiddenPreview,
    propertiesComponent: FormFieldHiddenProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'hidden',
            id: nanoid(),
            config: { ...defaultFormFieldHiddenConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldHiddenConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldHiddenConfig;
        const errors: string[] = [];
        if (!config.variable) errors.push(i18n.t('Variable name is required.'));

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldHiddenConfig;
        return config.variable || '';
    },
    getMatadataVariable: (field: FormField) => {
        const config = field.config as FormFieldHiddenConfig;
        return !config.excludeFromMetadata ? config.variable : '';
    },
    getFormattedValue: () => {
        return null;
    },
};
