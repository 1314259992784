import { nanoid } from 'nanoid';
import produce from 'immer';
import { FormFieldDropdownOption } from './form-field-dropdown-config';

export interface DropdownOptionsEditorState {
    options: FormFieldDropdownOption[];
    multiSelect: boolean;
}

export type DropdownOptionsEditorDispatchOnSave = {
    dispatchOnSave: () => void;
};

export type DropdownOptionsEditorCallbacks = DropdownOptionsEditorDispatchOnSave & {
    onSave: (options: FormFieldDropdownOption[]) => void;
    onCancel: () => void;
};

export enum DropdownOptionsEditorActionType {
    ADD_ITEM = 'add_item',
    UPDATE_ITEM = 'update_item',
    MOVE_ITEM = 'move_item',
    DELETE_ITEM = 'delete_item',
}

export type AddDropdownOptionAction = {
    type: DropdownOptionsEditorActionType.ADD_ITEM;
};

export type UpdateDropdownOptionAction = {
    type: DropdownOptionsEditorActionType.UPDATE_ITEM;
    payload: {
        option: FormFieldDropdownOption;
    };
};

export type DeleteDropdownOptionAction = {
    type: DropdownOptionsEditorActionType.DELETE_ITEM;
    payload: {
        id: string;
    };
};

export type MoveDropdownOptionAction = {
    type: DropdownOptionsEditorActionType.MOVE_ITEM;
    payload: {
        id: string;
        index: number;
    };
};

export type DropdownOptionsEditorAction =
    | AddDropdownOptionAction
    | UpdateDropdownOptionAction
    | DeleteDropdownOptionAction
    | MoveDropdownOptionAction;

export const dropdownOptionsEditorContextStateReducer = produce(
    (state: DropdownOptionsEditorState, action: DropdownOptionsEditorAction) => {
        switch (action.type) {
            case DropdownOptionsEditorActionType.ADD_ITEM: {
                state.options.push({
                    id: nanoid(),
                    label: '',
                    value: '',
                    show: true,
                    isDefault: false,
                });
                return state;
            }
            case DropdownOptionsEditorActionType.UPDATE_ITEM: {
                const index = state.options.findIndex((opt) => opt.id === action.payload.option.id);
                if (index >= 0) {
                    state.options[index] = { ...state.options[index], ...action.payload.option };
                    if (action.payload.option.isDefault && !state.multiSelect) {
                        state.options.forEach((opt) => {
                            if (opt.id !== action.payload.option.id && opt.isDefault) opt.isDefault = false;
                        });
                    }
                }
                return state;
            }
            case DropdownOptionsEditorActionType.MOVE_ITEM: {
                const oldIndex = state.options.findIndex((opt) => opt.id === action.payload.id);
                if (oldIndex >= 0 && action.payload.index >= 0 && action.payload.index < state.options.length)
                    state.options.splice(action.payload.index, 0, state.options.splice(oldIndex, 1)[0]);
                return state;
            }
            case DropdownOptionsEditorActionType.DELETE_ITEM: {
                state.options = state.options.filter((opt) => opt.id !== action.payload.id);
                return state;
            }
            default:
                return state;
        }
    },
);
