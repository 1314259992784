import { CSS } from '@dnd-kit/utilities';
import { PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { FormField } from 'contracts/forms';
import { usePlugins } from 'custom-hooks/use-plugins/use-plugins';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { useFormHelpers } from 'custom-hooks/use-form-helpers';
import { FormFieldPreview } from 'components/form-canvas-panel';
import { CustomIcon, CustomIconButton } from 'components/custom-controls';
import dragIcon from 'components/app-assets/icon-dragIndicator.svg';
import deleteIcon from './assets/icon-delete.svg';
import copyIcon from './assets/icon-copy.svg';
import { useTranslation } from 'react-i18next';

type SortableFormFieldPreviewProps = {
    id: string;
    index: number;
    field: FormField;
    disabled: boolean;
};

export const SortableFormFieldPreview = ({
    id,
    index,
    field,
    disabled,
    ...rest
}: PropsWithChildren<SortableFormFieldPreviewProps>) => {
    const { insertField, deleteField, selectField } = useFormActions();
    const { getFieldIndex, cloneField } = useFormHelpers();
    const { t } = useTranslation();
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
        data: {
            index,
            id,
            field,
            dragSource: 'fields-preview',
        },
        disabled,
    });
    const { getProvider } = usePlugins();
    const provider = getProvider(field.type);
    const formattedValue = provider.getFormattedValue(field);
    let metadataVar = provider.getMatadataVariable(field);
    if (metadataVar) metadataVar = `{form:${metadataVar}}`;

    const isDragOverlay = disabled;
    const isSelected = field.selected || isDragging;
    const fieldCss = isSelected ? 'py-9 pl-9 pr-7 z-10 bg-white' : '';
    const fieldRootCss = isSelected
        ? 'flex-1 relative mb-1 cursor-default mt-6'
        : 'flex-1 relative mb-1 cursor-default';

    const duplicateField = () => {
        if (index < 1) return;
        const newField = cloneField(field);
        insertField(newField, getFieldIndex(field.id) + 1);

        setTimeout(() => {
            selectField(newField.id);
        }, 60);
    };

    return (
        <div ref={setNodeRef} className={`${isDragOverlay ? 'h-2' : ''}`}>
            <div
                style={
                    isDragOverlay
                        ? {
                              transform: CSS.Transform.toString(transform),
                              transition,
                              top: -25,
                              left: 0,
                              pointerEvents: 'none',
                          }
                        : {
                              transform: CSS.Transform.toString(transform),
                              transition,
                              zIndex: isDragging ? 10 : 0,
                              opacity: isDragging ? 0.25 : 1,
                          }
                }
                {...attributes}
                className={fieldRootCss}
                onClick={() => {
                    selectField(field.id);
                }}>
                {isSelected && (
                    <div className="flex flex-row absolute inset-0 z-0 bg-stc-100 rounded-lg border border-stc-800">
                        <div className="flex-col">
                            <CustomIcon src={provider.icon} tooltip={provider.title} className="m-1.5 h-5" />
                            <div {...listeners}>
                                <CustomIconButton
                                    src={dragIcon}
                                    tooltip={disabled ? '' : t('Drag to reorder')}
                                    tooltipPlacement="left"
                                    className={`cursor-move -ml-0.5 ${isDragging || index < 1 ? 'hidden' : ''}`}
                                />
                            </div>
                        </div>
                        <div className="flex-1"></div>
                        <div className="flex-col -mt-8">
                            <div className={`flex-col h-4.5 ${isDragging || index < 1 ? 'opacity-0' : ''}`}>
                                <CustomIconButton
                                    src={copyIcon}
                                    tooltip={t('Create a duplicate field')}
                                    className={`h-4.5 ${isDragging || index < 1 ? 'hidden' : ''}`}
                                    onClick={duplicateField}
                                />
                                <CustomIconButton
                                    src={deleteIcon}
                                    tooltip={t('Delete field')}
                                    className={`h-4.5 ${isDragging || index < 1 ? 'hidden' : ''}`}
                                    onClick={() => {
                                        if (index > 0) deleteField(field.id);
                                    }}
                                />
                            </div>
                            {formattedValue && (
                                <div className="absolute bottom-1 left-10 text-sm text-amber-800">{formattedValue}</div>
                            )}
                            <div className="absolute bottom-1 right-7 text-sm text-gray-600">{metadataVar}</div>
                        </div>
                    </div>
                )}
                <div className={fieldCss}>
                    <FormFieldPreview field={field} {...rest} />
                </div>
            </div>
        </div>
    );
};
