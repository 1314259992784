import { FormFieldComponentProps } from 'contracts/forms';
import { CustomAccordion, CustomCheckbox, CustomTextField, CustomVariableNameField } from 'components/custom-controls';
import { useFormFieldHidden } from './use-form-field-hidden';
import { useTranslation } from 'react-i18next';

export const FormFieldHiddenProperties = (props: FormFieldComponentProps) => {
    const { config, onCustomTextFieldChange, onCustomCheckboxChange } = useFormFieldHidden(props);
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <CustomVariableNameField
                variable="variable"
                label={t('Variable Name')}
                defaultValue={config['variable']}
                maxLength={50}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="defaultValue"
                label={t('Default Value')}
                defaultValue={config['defaultValue']}
                maxLength={500}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomAccordion title={t('Actions')} expanded={true}>
                <div className="flex flex-col">
                    <CustomCheckbox
                        variable="excludeFromMetadata"
                        label={t('Exclude from metadata')}
                        defaultValue={config['excludeFromMetadata']}
                        onChange={onCustomCheckboxChange}
                    />
                </div>
            </CustomAccordion>
        </div>
    );
};
