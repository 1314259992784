export const useReviewApp = () => {
    let formsService = 'forms-service';

    if (localStorage) {
        const settingsJson = localStorage.getItem('sec.review.formbuilder.settings');
        if (settingsJson) {
            const settings = JSON.parse(settingsJson);
            if (settings && settings.formsService) formsService = settings.formsService;
        }
    }

    return {
        formsService,
    } as const;
};
