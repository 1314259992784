import { Paper } from '@mui/material';
import { CustomIconButton } from 'components/custom-controls';
import unpinIcon from 'components/app-assets/icon-unpinned.svg';
import { useTranslation } from 'react-i18next';

type FormPropertiesCollapsedPanelProps = {
    onPinButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const FormPropertiesCollapsedPanel = ({ onPinButtonClick }: FormPropertiesCollapsedPanelProps) => {
    const { t } = useTranslation();
    return (
        <Paper elevation={3} className="flex-col w-8 bg-stc-300">
            <CustomIconButton
                src={unpinIcon}
                tooltip={t('Pin to show Field Properties panel.')}
                onClick={onPinButtonClick}
            />
        </Paper>
    );
};
