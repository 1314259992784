import { useContext } from 'react';
import { DropdownOptionsEditorActionsApiContext } from './dropdown-options-editor-context-provider';
import {
    AddDropdownOptionAction,
    DeleteDropdownOptionAction,
    DropdownOptionsEditorActionType,
    MoveDropdownOptionAction,
    UpdateDropdownOptionAction,
} from './dropdown-options-editor-context-state-reducer';
import { FormFieldDropdownOption } from './form-field-dropdown-config';

export const useDropdownOptionsEditorActions = () => {
    const dispatch = useContext(DropdownOptionsEditorActionsApiContext);

    if (!dispatch) {
        throw new Error(
            'useDropdownOptionsEditorActions has to be used within <DropdownOptionsEditorActionsApiContext.Provider>',
        );
    }

    const addItem = () => {
        const action: AddDropdownOptionAction = {
            type: DropdownOptionsEditorActionType.ADD_ITEM,
        };
        dispatch(action);
    };

    const updateItem = (option: FormFieldDropdownOption) => {
        const action: UpdateDropdownOptionAction = {
            type: DropdownOptionsEditorActionType.UPDATE_ITEM,
            payload: {
                option,
            },
        };
        dispatch(action);
    };

    const moveItem = (id: string, index: number) => {
        const action: MoveDropdownOptionAction = {
            type: DropdownOptionsEditorActionType.MOVE_ITEM,
            payload: {
                id,
                index,
            },
        };
        dispatch(action);
    };

    const deleteItem = (id: string) => {
        const action: DeleteDropdownOptionAction = {
            type: DropdownOptionsEditorActionType.DELETE_ITEM,
            payload: {
                id,
            },
        };
        dispatch(action);
    };

    return {
        addItem,
        updateItem,
        moveItem,
        deleteItem,
    } as const;
};
