import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldTextAreaConfig, defaultFormFieldTextAreaConfig } from './form-field-textarea-config';
import { FormFieldTextAreaPreview } from './form-field-textarea-preview';
import { FormFieldTextAreaProperties } from './form-field-textarea-properties';
import { i18n } from 'i18n';
import textAreaImage from './assets/icon-form-field-textarea.svg';

export const FormFieldTextAreaProvider: FormFieldProvider = {
    type: 'textarea',
    title: i18n.t('Text Area'),
    category: 'standard',
    icon: textAreaImage,
    previewComponent: FormFieldTextAreaPreview,
    propertiesComponent: FormFieldTextAreaProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'textarea',
            id: nanoid(),
            config: { ...defaultFormFieldTextAreaConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldTextAreaConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldTextAreaConfig;
        const errors: string[] = [];
        const hasMin = config.minLength && config.minLength.trim();
        const hasMax = config.maxLength && config.maxLength.trim();
        if (!config.variable) errors.push(i18n.t('Variable name is required.'));
        if (!hasMin) errors.push(i18n.t('Min length is required.'));
        if (!hasMax) errors.push(i18n.t('Max length is required.'));
        if (hasMin && hasMax) {
            const min = parseInt(config.minLength);
            const max = parseInt(config.maxLength);
            if (isNaN(min)) errors.push(i18n.t('Invalid min length.'));
            else if (isNaN(max)) errors.push(i18n.t('Invalid max length.'));
            else if (min > max || max < 1) errors.push(i18n.t('Max length should be greater than min length.'));
            if (config.defaultValue) {
                const regexp = /{[-.\w]+:([\w-]+)}/g;
                const matches = config.defaultValue.match(regexp);
                if (matches === null || matches.length < 1) {
                    if (!(config.defaultValue.length >= min && config.defaultValue.length <= max))
                        errors.push(
                            i18n.t(
                                'The length of the default value should be in between the min and max length defined on this field.',
                            ),
                        );
                }
            }
        }
        if (config.required && config.readonly && config.defaultValue.trim() === '') {
            errors.push(
                i18n.t(
                    'A default value is required for this field, because it is designated as both Required and Read only.',
                ),
            );
        }

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldTextAreaConfig;
        return config.variable || '';
    },
    getMatadataVariable: (field: FormField) => {
        const config = field.config as FormFieldTextAreaConfig;
        return !config.excludeFromMetadata ? config.variable : '';
    },
    getFormattedValue: () => {
        return null;
    },
};
