import { useState } from 'react';
import { FormFieldsCollapsedPanel } from './form-fields-collapsed-panel';
import { FormFieldsExpandedPanel } from './form-fields-expanded-panel';

export const FormFieldsPanel = () => {
    const [isPinned, setIsPinned] = useState(true);

    const togglePinButtonState = () => {
        setIsPinned((prev) => !prev);
    };

    if (!isPinned) return <FormFieldsCollapsedPanel onPinButtonClick={togglePinButtonState} />;

    return <FormFieldsExpandedPanel onPinButtonClick={togglePinButtonState} />;
};

export * from './add-fields-instructions';
export * from './drag-overlay-form-field';
export * from './drag-overlay-form-field';
export * from './draggable-form-field';
export * from './form-field-icon';
export * from './form-fields-collapsed-panel';
export * from './form-fields-expanded-panel';
export * from './form-title';
export * from './standard-form-fields';
