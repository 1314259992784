import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldPageBreakConfig, defaultFormFieldPageBreakConfig } from './form-field-pagebreak-config';
import { FormFieldPageBreakPreview } from './form-field-pagebreak-preview';
import { FormFieldPageBreakProperties } from './form-field-pagebreak-properties';
import pageBreakImage from './assets/icon-form-field-pagebreak.svg';
import { i18n } from 'i18n';

export const FormFieldPageBreakProvider: FormFieldProvider = {
    type: 'pagebreak',
    title: i18n.t('Page Break'),
    category: 'standard',
    icon: pageBreakImage,
    previewComponent: FormFieldPageBreakPreview,
    propertiesComponent: FormFieldPageBreakProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'pagebreak',
            id: nanoid(),
            config: { ...defaultFormFieldPageBreakConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldPageBreakConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldPageBreakConfig;
        const errors: string[] = [];
        if (!config.variable) errors.push(i18n.t('Variable name is required.'));

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldPageBreakConfig;
        return config.variable || '';
    },
    getMatadataVariable: () => {
        return '';
    },
    getFormattedValue: () => {
        return null;
    },
};
