import { useContext } from 'react';
import { AppSnackbarContext } from 'context-providers/app-snackbar-context-provider';

export const useAppSnackbar = () => {
    const context = useContext(AppSnackbarContext);

    if (!context) {
        throw new Error('useAppSnackbar has to be used within <AppSnackbarContext.Provider>');
    }

    return context;
};
