import { useRef } from 'react';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldTextConfig, defaultFormFieldTextConfig } from './form-field-text-config';

export const useFormFieldText = ({ field, updateField }: FormFieldComponentProps) => {
    const configFromField = field.config as FormFieldTextConfig;
    const config = {
        ...defaultFormFieldTextConfig,
        ...configFromField,
    };
    const configRef = useRef<FormFieldTextConfig>(config);

    const onCustomTextFieldChange = (variable: string, value: string) => {
        updateConfig(variable as keyof FormFieldTextConfig, value);
    };

    const onCustomCheckboxChange = (variable: string, value: boolean) => {
        updateConfig(variable as keyof FormFieldTextConfig, value);
    };

    const updateConfig = <K extends keyof FormFieldTextConfig>(key: K, value: FormFieldTextConfig[K]) => {
        const updatedConfig = {
            ...configRef.current,
        };
        updatedConfig[key] = value;
        configRef.current = updatedConfig;

        const modifiedField = {
            ...field,
        };
        modifiedField.config = updatedConfig;
        updateField(modifiedField);
    };

    return {
        config,
        onCustomTextFieldChange,
        onCustomCheckboxChange,
    } as const;
};
