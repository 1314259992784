import produce from 'immer';
import { AppLoadingAction, AppLoadingActionType, AppLoadingState } from './app-loading-context-provider';

export const appLoadingStateReducer = produce((state: AppLoadingState, action: AppLoadingAction) => {
    switch (action.type) {
        case AppLoadingActionType.SHOW: {
            state.message.title = action.payload.message.title;
            state.message.text = action.payload.message.text;
            state.show = true;
            return state;
        }
        case AppLoadingActionType.HIDE: {
            state.show = false;
            return state;
        }
        default:
            return state;
    }
});
