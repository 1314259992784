import { Checkbox, FormControlLabel, FormGroup, Tooltip, Zoom } from '@mui/material';
import { useState } from 'react';
import { CustomTooltipPosition } from './custom-icon-button';

export type CustomCheckboxProps = {
    variable?: string;
    label: string;
    defaultValue: boolean;
    disabled?: boolean;
    className?: string;
    toggleOnAltText?: string;
    toggleOffAltText?: string;
    tooltipPlacement?: CustomTooltipPosition;
    onChange?: (variable: string, value: boolean) => void;
};

export const CustomCheckbox = ({
    variable,
    label,
    defaultValue,
    disabled = false,
    toggleOnAltText = '',
    toggleOffAltText = '',
    tooltipPlacement = 'top',
    className = '',
    onChange,
}: CustomCheckboxProps) => {
    const [controlValue, setControlValue] = useState(defaultValue);
    const tooltipText = controlValue ? toggleOnAltText : toggleOffAltText;

    return (
        <Tooltip title={tooltipText} placement={tooltipPlacement} TransitionComponent={Zoom} arrow>
            <FormGroup className={`${className}`}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={controlValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setControlValue(e.target.checked);
                                if (onChange) onChange(variable || '', e.target.checked);
                            }}
                        />
                    }
                    label={label}
                    className={`${label ? '' : 'm-0'}`}
                    disabled={disabled}
                />
            </FormGroup>
        </Tooltip>
    );
};
