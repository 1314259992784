import { Paper, Typography } from '@mui/material';
import { FormPropertiesExpandedPanelProps } from './form-properties-expanded-panel';
import { CustomIconButton } from 'components/custom-controls';
import pinIcon from 'components/app-assets/icon-pinned.svg';
import { useTranslation } from 'react-i18next';

export const FormPropertiesEmptyStage = ({ onPinButtonClick }: FormPropertiesExpandedPanelProps) => {
    const { t } = useTranslation();
    return (
        <Paper elevation={3} className="flex-col w-80">
            <div className="flex flex-row">
                <Typography variant="subtitle2" className="flex-1 ml-4 mt-2 font-semibold">
                    {t('Field Properties')}
                </Typography>
                <CustomIconButton
                    src={pinIcon}
                    tooltip={t('Unpin to hide Field Properties panel.')}
                    onClick={onPinButtonClick}
                />
            </div>
            <div className="ml-4 mr-4 mb-4 mt-1 flex-col">
                <div className="flex mt-52">
                    <Typography variant="caption" className="mx-auto">
                        {t('No form field is selected!')}
                    </Typography>
                </div>
            </div>
        </Paper>
    );
};
