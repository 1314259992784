import { Typography } from '@mui/material';
import { useFormActions } from 'custom-hooks/use-form-actions';
import emptyStageIcon from './assets/icon-mfp-stage.svg';
import { useTranslation } from 'react-i18next';

export const FormEmptyStage = () => {
    const { selectField } = useFormActions();
    const { t } = useTranslation();

    return (
        <div
            className="flex justify-center items-center h-64"
            onClick={() => {
                selectField(null);
            }}>
            <div className="flex-col justify-start items-center mt-32">
                <img src={emptyStageIcon} alt="Canvas empty stage" className="m-auto" />
                <div className="flex mt-12">
                    <Typography variant="caption" className="mx-auto">
                        {t('Drag and drop Standard Index Fields here.')}
                    </Typography>
                </div>
            </div>
        </div>
    );
};
