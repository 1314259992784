import React, { useMemo, useReducer } from 'react';
import { FormPluginsActionType, FormPluginsResetDndKeyAction, FormPluginsState } from 'contracts/form-plugins-state';
import { FormField, FormFieldComponentProps, FormFieldProvider, FormFieldStatus } from 'contracts/forms';
import { FormFieldUnknownProvider } from 'components/form-builder-plugins/form-field-unknown';
import { formPluginsStateReducer } from 'context-providers/form-plugins-context-provider';

type PluginsApiType = FormPluginsState & {
    getProvider: (pluginType: string) => FormFieldProvider;
    getField: (pluginType: string, suggestedVarName?: string) => FormField;
    getClonedField: (field: FormField, suggestedVarName?: string) => FormField;
    getFieldPreview: (pluginType: string) => React.ComponentType<FormFieldComponentProps>;
    getFieldProperties: (pluginType: string) => React.ComponentType<FormFieldComponentProps>;
    validateField: (field: FormField) => FormFieldStatus;
    resetFormPluginsDndKey: (pluginType: string) => void;
};

export const FormPluginsDraggableFieldIdsContext = React.createContext<FormPluginsState['draggableFieldIds']>(
    {} as FormPluginsState['draggableFieldIds'],
);

export const FormPluginsContext = React.createContext<PluginsApiType>({} as PluginsApiType);

export const FormPluginsContextProvider = ({
    initialState,
    children,
}: {
    initialState: FormPluginsState;
    children?: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(formPluginsStateReducer, initialState);

    const pluginsAPI = useMemo(() => {
        const getProvider = (pluginType: string): FormFieldProvider => {
            const provider = state.providers.find((p) => p.type === pluginType);
            return provider ? provider : FormFieldUnknownProvider;
        };

        const getField = (pluginType: string, suggestedVarName = ''): FormField => {
            return getProvider(pluginType).getField(suggestedVarName);
        };

        const getClonedField = (field: FormField, suggestedVarName = ''): FormField => {
            return getProvider(field.type).cloneField(JSON.parse(JSON.stringify(field)), suggestedVarName);
        };

        const validateField = (field: FormField): FormFieldStatus => {
            return getProvider(field.type).validate(field);
        };

        const getFieldPreview = (pluginType: string): React.ComponentType<FormFieldComponentProps> => {
            return getProvider(pluginType).previewComponent;
        };

        const getFieldProperties = (pluginType: string): React.ComponentType<FormFieldComponentProps> => {
            return getProvider(pluginType).propertiesComponent;
        };

        const resetFormPluginsDndKey = (pluginType: string) => {
            const action: FormPluginsResetDndKeyAction = {
                type: FormPluginsActionType.RESET_DND_KEY,
                payload: {
                    pluginType,
                },
            };
            dispatch(action);
        };

        return {
            getProvider,
            getField,
            getFieldPreview,
            getFieldProperties,
            getClonedField,
            validateField,
            resetFormPluginsDndKey,
            ...state,
        } as const;
        // We don't need to monitor for the state updates here, because once initiaized the plugin provider's initial state never gets updated.
        // But here eslinit thinks, the state might get updated in the future and throwing this warning.
        // Please don't try to pass any deps here, it has been done this way as part of the optimization.
        // It's a proactive warning from eslint and it can be safely ignored in this use case.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormPluginsContext.Provider value={pluginsAPI}>
            <FormPluginsDraggableFieldIdsContext.Provider value={state.draggableFieldIds}>
                {children}
            </FormPluginsDraggableFieldIdsContext.Provider>
        </FormPluginsContext.Provider>
    );
};

export * from './form-plugins-state-reducer';
export * from './form-plugins-constants';
