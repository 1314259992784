import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'custom-hooks/use-auth/use-auth';
import { useAppArgs } from 'custom-hooks/use-app-args';
import { Unauthorized } from 'components/unauthorized';

type ProtectedRouteProps = {
    children: React.ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const { state } = useAuth();
    const { search } = useAppArgs();

    if (state.isAuthenticated) return <>{children}</>;

    if (state.fetched && !state.isAuthenticated) return <Unauthorized />;

    return <Navigate to={`/${search}`} replace />;
};
