import { useState } from 'react';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { FormFieldDropdownOption } from './form-field-dropdown-config';
import { useDropdownOptionsEditorItems } from './use-dropdown-options-editor-items';
import { useDropdownOptionsEditorActions } from './use-dropdown-options-editor-actions';

export const useDropdownOptionsEditorDnd = () => {
    const { moveItem } = useDropdownOptionsEditorActions();
    const state = useDropdownOptionsEditorItems();
    const [activeItem, setActiveItem] = useState<FormFieldDropdownOption | null>(null);

    const onDragStart = (event: DragStartEvent) => {
        const { active } = event;
        const index = state.options.findIndex((opt) => opt.id === active.id.toString());

        if (index >= 0) setActiveItem(state.options[index]);
    };

    const onDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (!over || active.id === over?.id) return;

        const newIndex = state.options.findIndex((opt) => opt.id === over.id.toString());
        moveItem(active.id.toString(), newIndex);
        setActiveItem(null);
    };

    return {
        activeItem,
        onDragStart,
        onDragEnd,
    } as const;
};
