import { i18n } from 'i18n';

export type FormFieldNumberConfig = {
    variable: string;
    title: string;
    defaultValue: string;
    minValue: string;
    maxValue: string;
    required: boolean;
    readonly: boolean;
    mask: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText?: string;
};

export const defaultFormFieldNumberConfig: FormFieldNumberConfig = {
    variable: '',
    title: i18n.t('Number field friendly name goes here'),
    defaultValue: '',
    minValue: '0',
    maxValue: '99999999',
    required: false,
    readonly: false,
    mask: false,
    excludeFromMetadata: false,
    showHelp: false,
    helpText: '',
};
