import { useRef, useState } from 'react';
import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { FormFieldsDragSourceData } from 'components/form-fields-panel';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { usePlugins } from 'custom-hooks/use-plugins/use-plugins';
import { useFormFields } from 'custom-hooks/use-form-fields';
import { useFormHelpers } from 'custom-hooks/use-form-helpers/use-form-helpers';

export const useFormDnd = () => {
    const [activeFormFieldsDragData, SetActiveFormFieldsDragData] = useState<FormFieldsDragSourceData | null>(null);
    const { insertField, removeDropZones, replaceField, swapFields, selectField } = useFormActions();
    const { getField, resetFormPluginsDndKey } = usePlugins();
    const { getSuggestedFieldVarName } = useFormHelpers();
    const fields = useFormFields();
    const dropzoneRef = useRef<boolean>(false);

    const resetRefs = () => {
        SetActiveFormFieldsDragData(null);
        dropzoneRef.current = false;
        removeDropZones();
    };

    const onFormFieldsDragStart = (e: DragStartEvent) => {
        const { active } = e;
        const activeDragData = active.data.current as FormFieldsDragSourceData;

        selectField(null);
        if (activeDragData && !activeDragData.id) activeDragData.id = active.id.toString();
        SetActiveFormFieldsDragData(activeDragData);
    };

    const onFormFieldsDragOver = (e: DragOverEvent) => {
        const { active, over } = e;
        if (activeFormFieldsDragData?.dragSource === 'fields-panel') {
            const dragOverData = over?.data?.current as FormFieldsDragSourceData;
            if (!dropzoneRef.current) {
                const dropzone = getField('dropzone');
                dropzone.id = active.id + '-dropzone';

                if (!fields.length) {
                    insertField(dropzone, -1);
                } else {
                    // don't insert at 0 index, we need to keep the first page break field always at the top.
                    const nextIndex =
                        undefined !== dragOverData.index && dragOverData.index > 0 ? dragOverData.index : fields.length;
                    insertField(dropzone, nextIndex);
                }
                dropzoneRef.current = true;
            } else if (!over) {
                removeDropZones();
                dropzoneRef.current = false;
            } else {
                // don't allow droping at 0 index, we need to keep the first page break field always at the top.
                if (dragOverData.index) {
                    const dropzoneIndex = fields.findIndex((f) => f.id === active.id + '-dropzone');
                    const nextIndex =
                        undefined !== dragOverData.index && dragOverData.index > -1
                            ? dragOverData.index
                            : fields.length - 1;
                    if (nextIndex !== dropzoneIndex && undefined !== dragOverData.index) {
                        swapFields(dropzoneIndex, dragOverData.index);
                    }
                }
            }
        }
    };

    const onFormFieldsDragEnd = (e: DragEndEvent) => {
        const { over } = e;

        if (!over && activeFormFieldsDragData?.dragSource === 'fields-panel') {
            resetRefs();
            return;
        }

        const dragOverData = over?.data?.current as FormFieldsDragSourceData;
        let field = activeFormFieldsDragData?.field;
        if (!field && activeFormFieldsDragData?.dragSource === 'fields-panel') {
            const fieldType = activeFormFieldsDragData.type || '';
            field = getField(fieldType, getSuggestedFieldVarName(fieldType));
            if (activeFormFieldsDragData.id) field.id = activeFormFieldsDragData.id;

            resetFormPluginsDndKey(field.type);

            const dropZoneIndex = fields.findIndex((f) => f.type === 'dropzone');
            replaceField(dropZoneIndex, field);
        }

        if (field) {
            if (activeFormFieldsDragData?.dragSource === 'fields-preview') {
                const overItemIndex =
                    undefined !== dragOverData?.index && dragOverData?.index >= 0 ? dragOverData?.index : 0;

                // don't allow swaping field at 0 index, we need to keep the first page break field always at the top.
                if (overItemIndex > 0 && activeFormFieldsDragData.index)
                    swapFields(activeFormFieldsDragData.index, overItemIndex);
            }
            selectField(field.id);
        }
        resetRefs();
    };

    return {
        activeFormFieldsDragData,
        onFormFieldsDragStart,
        onFormFieldsDragOver,
        onFormFieldsDragEnd,
    } as const;
};
