import { ReactNode, forwardRef } from 'react';
import { CustomIconButton } from './custom-icon-button';
import { CustomDatePickerWrapper } from './custom-date-picker-wrapper';

export type CustomDatePickerProps = {
    variable?: string;
    label: string;
    defaultValue?: string;
    iconSrc: string;
    iconTooltip?: string;
    className?: string;
    onChange?: (variable: string, value: string) => void;
};

type DatePickerButtonProps = {
    children?: ReactNode;
    value?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const CustomDatePicker = (props: CustomDatePickerProps) => {
    const DatePickerButton = forwardRef<HTMLDivElement, DatePickerButtonProps>(({ onClick }, ref) => (
        <div ref={ref}>
            <CustomIconButton src={props.iconSrc} tooltip={props.iconTooltip} onClick={onClick} />
        </div>
    ));
    DatePickerButton.displayName = 'DatePickerButton';

    return <CustomDatePickerWrapper {...props} datePickerButton={<DatePickerButton />} />;
};
