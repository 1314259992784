import { Typography } from '@mui/material';
import { CustomIconButton } from 'components/custom-controls';
import { useDropdownOptionsEditorActions } from './use-dropdown-options-editor-actions';
import plusIcon from './assets/plus-icon.svg';
import { useTranslation } from 'react-i18next';

export type DropdownOptionsDialogHeaderProps = {
    className?: string;
};

export const DropdownOptionsDialogHeader = ({ className = '' }: DropdownOptionsDialogHeaderProps) => {
    const { addItem } = useDropdownOptionsEditorActions();
    const { t } = useTranslation();

    return (
        <div className={`flex flex-row p-1 rounded-t bg-stc-600 text-white ${className}`}>
            <div className=" w-28"></div>
            <div className="w-56 pl-1 items-center justify-center">
                <Typography variant="caption">{t('Label')}</Typography>
            </div>
            <div className="w-56 pl-4 items-center justify-center">
                <Typography variant="caption">{t('Value')}</Typography>
            </div>
            <div className="w-5 pl-10 pr-5 flex flex-row items-center justify-center">
                <CustomIconButton
                    src={plusIcon}
                    tooltip={t('Add a new Drop-Down option')}
                    lightRipple
                    onClick={addItem}
                />
            </div>
        </div>
    );
};
