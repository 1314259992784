import Zoom from '@mui/material/Zoom';
import { Tooltip } from '@mui/material';
import { CustomTooltipPosition } from './custom-icon-button';

type CustomIconProps = {
    src?: string;
    srcHover?: string;
    tooltip?: string;
    tooltipPlacement?: CustomTooltipPosition;
    className?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const CustomIcon = ({
    src,
    srcHover = '',
    tooltip = '',
    tooltipPlacement = 'top',
    className = '',
    children,
}: CustomIconProps) => {
    let contents = children;
    if (!contents)
        contents = (
            <img
                src={src}
                alt={tooltip}
                aria-label={tooltip}
                onMouseOver={(e): void => {
                    srcHover && (e.currentTarget.src = srcHover);
                }}
                onMouseOut={(e): void => {
                    srcHover && (e.currentTarget.src = src || '');
                }}
            />
        );

    return (
        <Tooltip title={tooltip} placement={tooltipPlacement} TransitionComponent={Zoom} arrow>
            <div className={`${className}`}>{contents}</div>
        </Tooltip>
    );
};
