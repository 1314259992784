import { FormFieldComponentProps } from 'contracts/forms';
import {
    CustomAccordion,
    CustomAddHelpText,
    CustomCheckbox,
    CustomTextField,
    CustomVariableNameField,
} from 'components/custom-controls';
import { useFormFieldPageBreak } from './use-form-field-pagebreak';
import { useTranslation } from 'react-i18next';

export const FormFieldPageBreakProperties = (props: FormFieldComponentProps) => {
    const { config, onCustomTextFieldChange, onCustomCheckboxChange } = useFormFieldPageBreak(props);
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <CustomVariableNameField
                variable="variable"
                label={t('Variable Name')}
                defaultValue={config['variable']}
                maxLength={50}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="title"
                label={t('Page Title')}
                defaultValue={config['title']}
                maxLength={100}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomAccordion title={t('Actions')} expanded={true}>
                <div className="flex flex-col">
                    {/* <CustomCheckbox
                        variable="hideBack"
                        label={t('Hide back button')}
                        defaultValue={config['hideBack']}
                        disabled={pageNumber === 1}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="hideNext"
                        label={t('Hide next button')}
                        defaultValue={config['hideNext']}
                        onChange={onCustomCheckboxChange}
                    /> */}
                    <CustomCheckbox
                        variable="hideHome"
                        label={t('Hide home button')}
                        defaultValue={config['hideHome']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="hideReturn"
                        label={t('Hide refresh button')}
                        defaultValue={config['hideReturn']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="hidePageNumbers"
                        label={t('Hide page numbers')}
                        defaultValue={config['hidePageNumbers']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomAddHelpText
                        checkboxVariable="showHelp"
                        checkboxLabel={t('Add help text')}
                        checkboxDefaultValue={config['showHelp']}
                        onCheckboxChange={onCustomCheckboxChange}
                        txtVariable="helpText"
                        txtLabel={t('Edit help text')}
                        txtDefaultValue={config['helpText'] || ''}
                        onTxtChange={onCustomTextFieldChange}
                    />
                </div>
            </CustomAccordion>
        </div>
    );
};
