import { TextField } from '@mui/material';
import { useState } from 'react';

export type CustomOutlinedInputProps = {
    variable?: string;
    label: string;
    defaultValue?: string;
    maxLength?: number;
    className?: string;
    onChange?: (variable: string, value: string) => void;
};

export const CustomOutlinedInput = ({
    variable,
    label,
    defaultValue = '',
    className = '',
    maxLength = 0,
    onChange,
}: CustomOutlinedInputProps) => {
    const [controlValue, setControlValue] = useState(defaultValue);

    return (
        <TextField
            variant="outlined"
            placeholder={label}
            size="small"
            className={`${className}`}
            defaultValue={controlValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setControlValue(event.target.value);
                if (onChange) onChange(variable || '', event.target.value);
            }}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (maxLength) {
                    const value = event.target.value.toString();
                    if (value.length > maxLength) event.target.value = value.slice(0, maxLength);
                }
            }}
        />
    );
};
