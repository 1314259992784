export enum FormActionType {
    INSERT_FIELD = 'insert_field',
    UPDATE_FIELD = 'update_field',
    MOVE_FIELD = 'move_field',
    DELETE_FIELD = 'delete_field',
    REPLACE_FIELD = 'replace_field',
    SWAP_FIELDS = 'swap_fields',
    RESET_PAGE_NUMBERS = 'reset_page_numbers',
    REMOVE_DROP_ZONES = 'remove_drpopzones',
    SELECT_FIELD = 'select_field',
    NEW_FORM = 'new_form',
    SAMPLE_FORM = 'sample_form',
    SET_TITLE = 'set_title',
    SET_ERRORS = 'set_errors',
    SET_SHOW_ERRORS = 'set_show_errors',
}
