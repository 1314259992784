import { isValid } from 'date-fns';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldDatePreview } from './form-field-date-preview';
import { nanoid } from 'nanoid';
import { FormFieldDateProperties } from './form-field-date-properties';
import { FormFieldDateConfig, defaultFormFieldDateConfig } from './form-field-date-config';
import { formatDateToLocale, i18n, localeToDate } from 'i18n';
import dateImage from './assets/icon-form-field-datetime.svg';

export const FormFieldDateProvider: FormFieldProvider = {
    type: 'date',
    title: i18n.t('Date and Time'),
    category: 'standard',
    icon: dateImage,
    previewComponent: FormFieldDatePreview,
    propertiesComponent: FormFieldDateProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'date',
            id: nanoid(),
            config: { ...defaultFormFieldDateConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldDateConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldDateConfig;
        const errors: string[] = [];
        if (!config.variable) errors.push(i18n.t('Variable name is required.'));

        const min = localeToDate(config.minValueLocale);
        const max = localeToDate(config.maxValueLocale);
        if (min || max) {
            if (config.minValue && !isValid(min)) errors.push(i18n.t('Invalid min value.'));
            if (config.maxValue && !isValid(max)) errors.push(i18n.t('Invalid max value.'));
            if (config.minValue && config.maxValue && isValid(min) && isValid(max) && min > max)
                errors.push(i18n.t('Max value should be greater than min value.'));
        }
        if (!config.defaultToNow && config.defaultValueLocale) {
            const regexp = /{[-.\w]+:([\w-]+)}/gi;
            const matches = config.defaultValueLocale.match(regexp);
            if (matches === null || matches.length < 1) {
                const defaultValue = localeToDate(config.defaultValueLocale);
                if (!isValid(defaultValue)) errors.push(i18n.t('Invalid default value.'));
                else if ((isValid(min) && defaultValue < min) || (isValid(max) && defaultValue > max))
                    errors.push(
                        i18n.t('The default value should be in between the min and max values defined on this field.'),
                    );
            }
        }
        if (config.required && config.readonly && !config.defaultToNow && config.defaultValue.trim() === '') {
            errors.push(
                i18n.t(
                    'A default value is required for this field, because it is designated as both Required and Read only.',
                ),
            );
        }

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldDateConfig;
        return config.variable || '';
    },
    getMatadataVariable: (field: FormField) => {
        const config = field.config as FormFieldDateConfig;
        return !config.excludeFromMetadata ? config.variable : '';
    },
    getFormattedValue: (field: FormField) => {
        const config = field.config as FormFieldDateConfig;
        if (!config.defaultToNow && !config.defaultValue) return '';

        const defaultValue = config.defaultToNow ? formatDateToLocale(new Date()) : config.defaultValueLocale || '';
        if (!config.returnFormat) return defaultValue;

        const date = localeToDate(defaultValue);
        if (!isValid(date)) return '';

        return formatDateToLocale(date, config.returnFormat);
    },
};
