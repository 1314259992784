import { CustomHelpIcon } from 'components/custom-controls';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldNumberConfig } from './form-field-number-config';
import { CustomReadOnlyTextField } from 'components/custom-controls';

export const FormFieldNumberPreview = ({ field }: FormFieldComponentProps) => {
    const config = field.config as FormFieldNumberConfig;
    const defaultValue = config.mask ? config.defaultValue.replace(/./g, '*') : config.defaultValue;

    return (
        <div className="flex flex-row">
            <CustomReadOnlyTextField
                label={`${config.title} ${config.required ? '*' : ''}`}
                defaultValue={defaultValue}
                className="flex-1"
            />
            {config.showHelp && <CustomHelpIcon className="ml-3" />}
        </div>
    );
};
