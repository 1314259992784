import { AppActions } from 'components/app-actions';
import { AppFooter } from 'components/app-footer';
import { AppHeader } from 'components/app-header';
import { AppInstructions } from 'components/app-instructions';
import { AppSidebar } from 'components/app-sidebar';
import { NewFormDialog } from 'components/new-form-dialog';
import { FormBuilderDndPanels } from './form-builder dnd-panels';
import { FormPropertiesPanel } from 'components/form-properties-panel';
import { FormFieldsPanel } from 'components/form-fields-panel';
import { FormCanvasPanel } from 'components/form-canvas-panel';

export const FormBuilder = () => {
    return (
        <div className="flex flex-col h-screen">
            <AppHeader />
            <div className="flex flex-1 overflow-hidden">
                <AppSidebar />
                <div className="flex flex-1 flex-col">
                    <AppInstructions />
                    <div className="flex flex-1 p-4 overflow-hidden">
                        <FormBuilderDndPanels fieldsPanel={<FormFieldsPanel />} canvasPanel={<FormCanvasPanel />} />
                        <FormPropertiesPanel />
                    </div>
                    <AppActions />
                    <AppFooter />
                </div>
            </div>
            <NewFormDialog />
        </div>
    );
};
