import { CustomHelpIcon } from 'components/custom-controls';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldTextAreaConfig } from './form-field-textarea-config';
import { CustomReadOnlyTextArea } from 'components/custom-controls';

export const FormFieldTextAreaPreview = ({ field }: FormFieldComponentProps) => {
    const config = field.config as FormFieldTextAreaConfig;

    return (
        <div className="flex flex-row">
            <CustomReadOnlyTextArea
                label={`${config.title} ${config.required ? '*' : ''}`}
                rows={3}
                defaultValue={config.defaultValue}
                className="flex-1"
            />
            {config.showHelp && <CustomHelpIcon className="ml-3" />}
        </div>
    );
};
