import { CustomAccordion } from 'components/custom-controls';
import { useTranslation } from 'react-i18next';

export type StandardFormFieldsProps = {
    fields: React.ReactNode;
};

export const StandardFormFields = ({ fields }: StandardFormFieldsProps) => {
    const { t } = useTranslation();
    return (
        <CustomAccordion title={t('Standard Index Fields')} expanded={true} className="ml-3 mr-6">
            <div className="flex flex-wrap mt-1">{fields}</div>
        </CustomAccordion>
    );
};
