import Zoom from '@mui/material/Zoom';
import { IconButton, Tooltip } from '@mui/material';
import { CustomTooltipPosition } from './custom-icon-button';

type CustomIconToggleButtonProps = {
    variable?: string;
    defaultValue: boolean;
    toggleOnIcon: string;
    toggleOffIcon: string;
    toggleOnAltText: string;
    toggleOffAltText: string;
    tooltipPlacement?: CustomTooltipPosition;
    className?: string;
    disabled?: boolean;
    onChange?: (variable: string, toggleState: boolean) => void;
};

export const CustomIconToggleButton = ({
    variable,
    defaultValue = false,
    disabled = false,
    className = '',
    toggleOnIcon,
    toggleOffIcon,
    toggleOnAltText,
    toggleOffAltText,
    tooltipPlacement = 'top',
    onChange,
}: CustomIconToggleButtonProps) => {
    const onToggle = () => {
        const newState = !defaultValue;
        if (onChange) onChange(variable || '', newState);
    };
    const opacity = disabled ? 'opacity-25' : '';

    if (defaultValue) {
        return (
            <Tooltip title={toggleOnAltText} placement={tooltipPlacement} TransitionComponent={Zoom} arrow>
                <IconButton
                    className={`${className} ${opacity}`}
                    aria-label={toggleOnAltText}
                    component="label"
                    onClick={onToggle}
                    disabled={disabled}>
                    <img src={toggleOnIcon} alt={toggleOnAltText} />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={toggleOffAltText} placement={tooltipPlacement} TransitionComponent={Zoom} arrow>
            <IconButton
                className={`${className} ${opacity}`}
                aria-label={toggleOffAltText}
                component="label"
                onClick={onToggle}
                disabled={disabled}>
                <img src={toggleOffIcon} alt={toggleOffAltText} />
            </IconButton>
        </Tooltip>
    );
};
