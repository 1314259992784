import { FormPluginsContext } from 'context-providers/form-plugins-context-provider';
import { useContext } from 'react';

export const usePlugins = () => {
    const context = useContext(FormPluginsContext);

    if (!context) {
        throw new Error('usePlugins has to be used within <PluginsContext.Provider>');
    }

    return context;
};
