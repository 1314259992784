import { TextField } from '@mui/material';
import { useState } from 'react';

export type CustomTextAreaProps = {
    variable?: string;
    label: string;
    defaultValue: string;
    maxLength?: number;
    rows: number;
    className?: string;
    onChange?: (variable: string, value: string) => void;
};

export const CustomTextArea = ({
    variable,
    label,
    defaultValue,
    maxLength = 0,
    rows,
    className = '',
    onChange,
}: CustomTextAreaProps) => {
    const [controlValue, setControlValue] = useState(defaultValue);

    return (
        <TextField
            label={label}
            size="small"
            className={`break-words ${className}`}
            defaultValue={controlValue}
            multiline
            rows={rows}
            InputLabelProps={{ shrink: true }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setControlValue(event.target.value);
                if (onChange) onChange(variable || '', event.target.value);
            }}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (maxLength) {
                    const value = event.target.value.toString();
                    if (value.length > maxLength) event.target.value = value.slice(0, maxLength);
                }
            }}
        />
    );
};
