import { FormFieldComponentProps } from 'contracts/forms';
import { useFormFieldLabel } from './use-form-field-label';
import { CustomTextArea, CustomVariableNameField } from 'components/custom-controls';
import { useTranslation } from 'react-i18next';

export const FormFieldLabelProperties = (props: FormFieldComponentProps) => {
    const { config, onCustomTextFieldChange } = useFormFieldLabel(props);
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <CustomVariableNameField
                variable="variable"
                label={t('Variable Name')}
                defaultValue={config['variable']}
                maxLength={50}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextArea
                variable="title"
                label={t('Friendly Name (Viewable)')}
                defaultValue={config['title']}
                maxLength={2000}
                rows={8}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
        </div>
    );
};
