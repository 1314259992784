import React, { useMemo, useReducer } from 'react';
import {
    AuthState,
    AuthAction,
    authContextReducer,
    AuthActionType,
    SetTokenAuthAction,
    TokenErrorAuthAction,
} from './';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthRegions } from './auth-context-state';
import { useAppArgs } from 'custom-hooks/use-app-args';

type AuthActionsApi = {
    handleAuth: () => void;
    setToken: (token: string) => void;
    setTokenError: () => void;
};

export type AuthContextType = {
    state: AuthState;
    dispatch: (action: AuthAction) => void;
};

export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);
export const AuthApiContext = React.createContext<AuthActionsApi>({} as AuthActionsApi);

const getRegionFromToken = (token: string): AuthRegions => {
    const decodedToken = jwtDecode<{ iss: string }>(token);
    const iss = decodedToken.iss;
    return iss.split('.')[1] as AuthRegions;
};

export const AuthContextProvider = ({
    initialState,
    children,
}: {
    initialState: AuthState;
    children?: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(authContextReducer, initialState);
    const { tenantSlug, tenantUrl, search } = useAppArgs();
    const navigate = useNavigate();

    const authApi = useMemo(() => {
        const setToken = (token: string) => {
            try {
                const region = getRegionFromToken(token);

                const action: SetTokenAuthAction = {
                    type: AuthActionType.SET_TOKEN,
                    payload: {
                        token: token,
                        region: region,
                    },
                };

                dispatch(action);
            } catch (error) {
                const action: TokenErrorAuthAction = {
                    type: AuthActionType.TOKEN_ERROR,
                };

                dispatch(action);
            }
        };

        const setTokenError = () => {
            const action: TokenErrorAuthAction = {
                type: AuthActionType.TOKEN_ERROR,
            };
            dispatch(action);
        };

        const handleAuth = async (): Promise<boolean> => {
            const httpClient = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const url = `https://${tenantUrl}/api/sso/service/${tenantSlug}`;
            try {
                const response = await httpClient.get(url, {
                    withCredentials: true,
                });

                if (response.data && response.data?.token) {
                    setToken(response.data.token);
                    navigate(`/app${search}`, { replace: true });
                    return true;
                } else if (response.data && response.data?.error) {
                    setTokenError();
                    navigate(`/unauthorized${search}`, { replace: true });
                }
            } catch (error) {
                console.log(error);
                setTokenError();
                navigate(`/unauthorized${search}`, { replace: true });
            }
            return false;
        };

        return {
            handleAuth,
            setToken,
            setTokenError,
        } as const;
    }, [navigate, search, tenantSlug, tenantUrl]);

    return (
        <AuthApiContext.Provider value={authApi}>
            <AuthContext.Provider
                value={{
                    state,
                    dispatch,
                }}>
                {children}
            </AuthContext.Provider>
        </AuthApiContext.Provider>
    );
};

export * from './auth-context-reducer';
export * from './auth-context-state';
export * from './auth-context-action';
