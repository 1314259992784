import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldLabelConfig } from './form-field-label-config';
import { useTranslation } from 'react-i18next';

export const FormFieldLabelPreview = ({ field }: FormFieldComponentProps) => {
    const { t } = useTranslation();
    const config = field.config as FormFieldLabelConfig;

    return <div className="flex flex-col py-2 break-all">{config.title.trim() || t('Label')}</div>;
};
