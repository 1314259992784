import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AddFieldsInstructions = () => {
    const { t } = useTranslation();
    return (
        <div className="flex-col pl-4 mt-2">
            <div className="flex text-base font-semibold">
                <Typography variant="subtitle2" className="font-semibold">
                    {t('Add Fields')}
                </Typography>
            </div>
            <div className="flex pr-4 pt-2 pb-2 text-sm">
                <Typography variant="caption">{t('Drag a field to the right to start building your form.')}</Typography>
            </div>
        </div>
    );
};
