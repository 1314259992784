import { DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DropdownOptionsEditorSortableItem } from './dropdown-options-editor-sortable-item';
import { useDropdownOptionsEditorDnd } from './use-dropdown-options-editor-dnd';
import { useDropdownOptionsEditorItems } from './use-dropdown-options-editor-items';
import { useDropdownOptionsEditorSetCallbacks } from './use-dropdown-options-editor-set-callbacks';
import { useDropdownOptionsEditorCallbacks } from './use-dropdown-options-editor-callbacks';

type DropdownOptionsEditorProps = {
    className?: string;
};

export const DropdownOptionsEditor = ({ className = '' }: DropdownOptionsEditorProps) => {
    const { activeItem, onDragStart, onDragEnd } = useDropdownOptionsEditorDnd();
    const state = useDropdownOptionsEditorItems();
    const editorCallbacks = useDropdownOptionsEditorCallbacks();
    const setDispatchOnSaveCallback = useDropdownOptionsEditorSetCallbacks();

    function handleOnSave() {
        if (editorCallbacks.onSave) editorCallbacks.onSave(state.options);
    }

    if (setDispatchOnSaveCallback) setDispatchOnSaveCallback({ dispatchOnSave: handleOnSave });

    const editorItems = state.options.map((opt, index) => (
        <DropdownOptionsEditorSortableItem
            key={opt.id}
            id={opt.id}
            label={opt.label}
            value={opt.value}
            show={opt.show}
            isDefault={opt.isDefault}
            multiSelect={state.multiSelect}
            index={index}
            disabled={false}
        />
    ));

    const dragOverlayItem = activeItem ? (
        <DropdownOptionsEditorSortableItem
            id={activeItem.id}
            label={activeItem.label}
            value={activeItem.value}
            show={activeItem.show}
            isDefault={activeItem.isDefault}
            multiSelect={state.multiSelect}
            index={0}
            disabled={true}
            className="bg-stc-100 rounded-lg border border-stc-800"
        />
    ) : null;

    return (
        <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} autoScroll>
            <SortableContext strategy={verticalListSortingStrategy} items={state.options.map((opt) => opt.id)}>
                <div className={`flex flex-col p-2 h-72 overflow-hidden overflow-y-auto  ${className}`}>
                    {editorItems}
                </div>
            </SortableContext>

            <DragOverlay>{dragOverlayItem}</DragOverlay>
        </DndContext>
    );
};
