import { CustomIcon } from './custom-icon';
import helpIcon from 'components/app-assets/help-icon.svg';

type CustomHelpIconProps = {
    className?: string;
};

export const CustomHelpIcon = ({ className = '' }: CustomHelpIconProps) => {
    return <CustomIcon src={helpIcon} className={`${className} m-auto`}></CustomIcon>;
};
