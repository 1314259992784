import { i18n } from 'i18n';

export type FormFieldCheckboxConfig = {
    variable: string;
    title: string;
    defaultValue: string;
    trueLabel: string;
    falseLabel: string;
    readonly: boolean;
    checked: boolean;
    buttonsList: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText?: string;
};

export const defaultFormFieldCheckboxConfig: FormFieldCheckboxConfig = {
    variable: '',
    title: i18n.t('Checkmark field friendly name goes here'),
    defaultValue: '',
    trueLabel: i18n.t('Yes'),
    falseLabel: i18n.t('No'),
    readonly: false,
    checked: false,
    buttonsList: false,
    excludeFromMetadata: false,
    showHelp: false,
    helpText: '',
};
