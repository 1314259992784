export type FormFieldHiddenConfig = {
    variable: string;
    defaultValue: string;
    excludeFromMetadata: boolean;
};

export const defaultFormFieldHiddenConfig: FormFieldHiddenConfig = {
    variable: '',
    defaultValue: '',
    excludeFromMetadata: false,
};
