import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App } from 'components/app';
import { TestForms } from 'components/test-forms';
import { theme } from 'theme/theme';
import { AppSnackbarContextProvider, AppSnackbarState } from 'context-providers/app-snackbar-context-provider';
import { AppLoadingContextProvider, AppLoadingState } from 'context-providers/app-loading-context-provider';
import { AppLoading } from 'components/app-loading';
import { AppSnackbar } from 'components/app-snackbar';
import { AuthContextProvider, AuthState } from 'context-providers/auth-context-provider/auth-context-provider';
import { AuthRedirect } from 'components/auth-redirect/auth-redirect';
import { ProtectedRoute } from 'components/protected-route';
import { Unauthorized } from 'components/unauthorized/unauthorized';
import { I18nextProvider } from 'react-i18next';
import { i18n } from 'i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

const appSnackbarInitialState: AppSnackbarState = {
    show: false,
    message: { text: '' },
    messageType: 'success',
};

const appLoadingInitialState: AppLoadingState = {
    show: true,
    message: { title: 'Loading Application', text: 'Please wait . . .' },
};

const authState: AuthState = {
    isAuthenticated: false,
    token: undefined,
    fetched: false,
    region: undefined,
};

root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <I18nextProvider i18n={i18n}>
                        <AuthContextProvider initialState={authState}>
                            <ThemeProvider theme={theme}>
                                <AppSnackbarContextProvider initialState={appSnackbarInitialState}>
                                    <AppLoadingContextProvider initialState={appLoadingInitialState}>
                                        <Routes>
                                            <Route path="/" element={<AuthRedirect />} />
                                            <Route
                                                path="/app"
                                                element={
                                                    <ProtectedRoute>
                                                        <App />
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route path="/test/forms" element={<TestForms />} />
                                            <Route path="/unauthorized" element={<Unauthorized />} />
                                        </Routes>
                                        <>
                                            <AppLoading />
                                            <AppSnackbar />
                                        </>
                                    </AppLoadingContextProvider>
                                </AppSnackbarContextProvider>
                            </ThemeProvider>
                        </AuthContextProvider>
                    </I18nextProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </StyledEngineProvider>
    </React.StrictMode>,
);
