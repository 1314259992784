import { FormFieldComponentProps } from 'contracts/forms';
import {
    CustomAccordion,
    CustomAddHelpText,
    CustomCheckbox,
    CustomTextField,
    CustomVariableNameField,
} from 'components/custom-controls';
import { useFormFieldCheckbox } from './use-form-field-checkbox';
import { useTranslation } from 'react-i18next';

export const FormFieldCheckboxProperties = (props: FormFieldComponentProps) => {
    const { config, onCustomTextFieldChange, onCustomCheckboxChange } = useFormFieldCheckbox(props);
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <CustomVariableNameField
                variable="variable"
                label={t('Variable Name')}
                defaultValue={config['variable']}
                maxLength={50}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="title"
                label={t('Friendly Name (Viewable)')}
                defaultValue={config['title']}
                maxLength={100}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="trueLabel"
                label={t('True Button label')}
                defaultValue={config['trueLabel']}
                maxLength={20}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="falseLabel"
                label={t('False Button label')}
                defaultValue={config['falseLabel']}
                maxLength={20}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />

            <CustomAccordion title={t('Actions')} expanded={true}>
                <div className="flex flex-col">
                    <CustomCheckbox
                        variable="checked"
                        label={t('Check by default')}
                        defaultValue={config['checked']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="readonly"
                        label={t('Read only')}
                        defaultValue={config['readonly']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="buttonsList"
                        label={t('Buttons list')}
                        defaultValue={config['buttonsList']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="excludeFromMetadata"
                        label={t('Exclude from metadata')}
                        defaultValue={config['excludeFromMetadata']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomAddHelpText
                        checkboxVariable="showHelp"
                        checkboxLabel={t('Add help text')}
                        checkboxDefaultValue={config['showHelp']}
                        onCheckboxChange={onCustomCheckboxChange}
                        txtVariable="helpText"
                        txtLabel={t('Edit help text')}
                        txtDefaultValue={config['helpText'] || ''}
                        onTxtChange={onCustomTextFieldChange}
                    />
                </div>
            </CustomAccordion>
        </div>
    );
};
