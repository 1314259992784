import { useContext } from 'react';
import { AuthApiContext } from 'context-providers/auth-context-provider';

export const useAuthApi = () => {
    const context = useContext(AuthApiContext);

    if (!context) {
        throw new Error('useAuthApi has to be used within <AuthApiContext.Provider>');
    }

    return context;
};
