import { Typography } from '@mui/material';
import { CustomIcon } from 'components/custom-controls';
import { CustomHelpIcon } from 'components/custom-controls';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldPageBreakConfig } from './form-field-pagebreak-config';
import homwIcon from './assets/mfp_home_button.svg';
import backIcon from './assets/mfp_back_button.svg';
import forwardIcon from './assets/mfp_forward_button.svg';
import returnIcon from './assets/mfp_return_button.svg';
import { useTranslation } from 'react-i18next';

export const FormFieldPageBreakPreview = ({ field }: FormFieldComponentProps) => {
    const config = field.config as FormFieldPageBreakConfig;
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-end mb-2">
                <div className="flex flex-row">
                    {!config.hideHome && <CustomIcon src={homwIcon} className="opacity-70" />}
                    {!config.hideReturn && <CustomIcon src={returnIcon} className="ml-1 opacity-70" />}
                    {field.pageNumber !== 1 && !config.hideBack && (
                        <CustomIcon src={backIcon} className="ml-1 opacity-50" />
                    )}
                    {!config.hideNext && <CustomIcon src={forwardIcon} className="ml-1 opacity-50" />}
                </div>
            </div>
            {!config.hidePageNumbers && (
                <div className="flex justify-end mr-1 text-sm text-gray-600">
                    {'(' +
                        t('Page {{pageNumber}} of {{totalPages}}', {
                            pageNumber: field.pageNumber,
                            totalPages: field.totalPages,
                        }) +
                        ')'}
                </div>
            )}
            <div className="flex relative pt-1 items-center">
                <div className="flex-grow border-2 border-dashed border-gray-400"></div>
                <span className="flex-shrink mx-4 text-gray-600">{t('Page Break')}</span>
                <div className="flex-grow border-2 border-dashed border-gray-400"></div>
            </div>
            <div className="flex flex-row">
                <div className="flex-1 overflow-hidden text-ellipsis pb-1">
                    <Typography variant="h6" className="text-base font-semibold mb-2 break-all">
                        {config.title || ''}
                    </Typography>
                </div>
                {config.showHelp && <CustomHelpIcon className="ml-3" />}
            </div>
        </div>
    );
};
