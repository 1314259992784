import { useContext } from 'react';
import { DropdownOptionsEditorItemsContext } from './dropdown-options-editor-context-provider';

export const useDropdownOptionsEditorItems = () => {
    const context = useContext(DropdownOptionsEditorItemsContext);

    if (!context) {
        throw new Error(
            'useDropdownOptionsEditorItems has to be used within <DropdownOptionsEditorItemsContext.Provider>',
        );
    }
    return context;
};
