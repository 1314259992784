import { useContext } from 'react';
import { AppLoadingApiContext } from 'context-providers/app-loading-context-provider';

export const useAppLoadingApi = () => {
    const context = useContext(AppLoadingApiContext);

    if (!context) {
        throw new Error('useAppLoadingApi has to be used within <AppLoadingApiContext.Provider>');
    }

    return context;
};
