import React, { useRef } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { MfpPanelIconsList } from './mfp-panel-icons-list';
import { useNewForm } from './useNewForm';
import { useFormShowNewDialog } from 'custom-hooks/use-form-show-new-dialog';
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogBody,
    CustomDialogTitle,
    CustomIconButton,
} from 'components/custom-controls';
import editIcon from './assets/icon-edit.svg';
import { useTranslation } from 'react-i18next';

export const NewFormDialog = () => {
    const {
        mfpPanelSizes,
        sampleForms,
        newFormOptions,
        onTargetMfpChange,
        onSampleFormChange,
        startNewForm,
        updateNewFormOptions,
        backToFormsManagement,
    } = useNewForm();
    const showNewForm = useFormShowNewDialog();
    const titleInput = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const onEditButtonClick = () => {
        titleInput?.current?.focus();
        titleInput?.current?.select();
    };

    return (
        <CustomDialog show={showNewForm}>
            <CustomDialogTitle>
                <div className="flex flex-row">
                    <div className="flex-1">
                        <Typography variant="h6" className="text-base pl-2 mt-1">
                            {t('Create New Form')}
                        </Typography>
                    </div>
                </div>
            </CustomDialogTitle>
            <CustomDialogBody>
                <div className=" max-w-[600px]">
                    <div>
                        <Typography variant="caption">
                            {t('Enter a name for the form you would like to create.')}
                        </Typography>
                    </div>
                    <div className="felx-col ml-4 mr-8 mt-2">
                        <div className="relative mb-4 w-72">
                            <TextField
                                variant="standard"
                                label={t('Form Name') + '*'}
                                size="small"
                                className="w-64"
                                inputRef={titleInput}
                                autoFocus
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    updateNewFormOptions('title', event.target.value);
                                }}
                                InputProps={{
                                    style: {
                                        paddingRight: 40,
                                    },
                                }}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.value.toString();
                                    if (value.length > 100) event.target.value = value.slice(0, 100);
                                }}
                            />

                            <div className="absolute right-6 top-2">
                                <CustomIconButton
                                    src={editIcon}
                                    tooltip={t('Edit form name')}
                                    onClick={onEditButtonClick}
                                />
                            </div>
                        </div>

                        <div className="mb-2 hidden">
                            <Typography variant="caption">
                                {t('Select MFP Size (This cannot be changed later)')}
                            </Typography>
                        </div>
                        <div className="pl-4 pr-4 hidden">
                            <MfpPanelIconsList items={mfpPanelSizes} onListChange={onTargetMfpChange} />
                        </div>

                        <div className="mt-5 mb-2">
                            <Typography variant="caption">{t('Select Sample')}</Typography>
                        </div>
                        <div className="pl-4 pr-4">
                            <MfpPanelIconsList items={sampleForms} onListChange={onSampleFormChange} />
                        </div>
                    </div>
                </div>
            </CustomDialogBody>
            <CustomDialogActions>
                <div className="float-right">
                    <Button variant="outlined" onClick={backToFormsManagement} className="mr-4">
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => startNewForm()}
                        disabled={!newFormOptions.title || !newFormOptions.title.trim()}
                        className="mr-1">
                        {t('Continue')}
                    </Button>
                </div>
            </CustomDialogActions>
        </CustomDialog>
    );
};

export * from './mfp-panel-icon';
export * from './mfp-panel-icons-list';
export * from './mfp-panel-item';
export * from './useNewForm';
