import React from 'react';

type CustomDialogBodyProps = {
    children: React.ReactNode;
    className?: string;
};

export const CustomDialogBody = ({ children, className = '' }: CustomDialogBodyProps) => {
    return <div className={`relative p-5 flex-auto flex-col ${className}`}>{children}</div>;
};
