import { useRef } from 'react';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldCheckboxConfig, defaultFormFieldCheckboxConfig } from './form-field-checkbox-config';

export const useFormFieldCheckbox = ({ field, updateField }: FormFieldComponentProps) => {
    const configFromField = field.config as FormFieldCheckboxConfig;
    const config = {
        ...defaultFormFieldCheckboxConfig,
        ...configFromField,
    };
    const configRef = useRef<FormFieldCheckboxConfig>(config);

    const onCustomTextFieldChange = (variable: string, value: string) => {
        updateConfig(variable as keyof FormFieldCheckboxConfig, value);
    };

    const onCustomCheckboxChange = (variable: string, value: boolean) => {
        updateConfig(variable as keyof FormFieldCheckboxConfig, value);
    };

    const updateConfig = <K extends keyof FormFieldCheckboxConfig>(key: K, value: FormFieldCheckboxConfig[K]) => {
        const updatedConfig = {
            ...configRef.current,
        };
        updatedConfig[key] = value;
        configRef.current = updatedConfig;

        const modifiedField = {
            ...field,
        };
        modifiedField.config = updatedConfig;
        updateField(modifiedField);
    };

    return {
        config,
        onCustomTextFieldChange,
        onCustomCheckboxChange,
    } as const;
};
