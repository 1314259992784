import { TextField } from '@mui/material';

export type CustomReadOnlyTextAreaProps = {
    variable?: string;
    label: string;
    defaultValue: string;
    rows: number;
    className?: string;
};

export const CustomReadOnlyTextArea = ({ label, defaultValue, rows, className = '' }: CustomReadOnlyTextAreaProps) => {
    return (
        <TextField
            label={label}
            size="small"
            className={`${className}`}
            value={defaultValue || ''}
            multiline
            rows={rows}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true }}
        />
    );
};
