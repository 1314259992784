import { useHttp } from 'custom-hooks/use-http';
import { UserInfoResponse } from './user-info-response';
import { UserInfo } from './user-info';

export const useHttpLoadUserInfo = () => {
    const { baseUrl, httpClient, parseHttpFormErrors } = useHttp();

    const loadUserInfo = async (): Promise<UserInfoResponse> => {
        const url = `${baseUrl}/userinfo`;
        try {
            const response = await httpClient.get(url);
            const userInfo = response.data as UserInfo;

            return { data: userInfo };
        } catch (error) {
            return { error: parseHttpFormErrors(error).error };
        }
    };

    return {
        loadUserInfo,
    } as const;
};

export * from './user-info';
export * from './user-info-response';
