import { useContext } from 'react';
import { AppSnackbarApiContext } from 'context-providers/app-snackbar-context-provider';

export const useAppSnackbarApi = () => {
    const context = useContext(AppSnackbarApiContext);

    if (!context) {
        throw new Error('useAppSnackbarApi has to be used within <AppSnackbarApiContext.Provider>');
    }

    return context;
};
