import axios from 'axios';
import { ApiStatus, FormResponse, ValidateErrorResponse } from 'contracts/forms';
import { useAuth } from 'custom-hooks/use-auth/use-auth';
import { useAuthApi } from 'custom-hooks/use-auth-api/use-auth-api';
import { useAppArgs } from 'custom-hooks/use-app-args';
import { useTranslation } from 'react-i18next';
import { useReviewApp } from 'custom-hooks/use-review-app';

export const useHttp = () => {
    const { state: authState } = useAuth();
    const { setTokenError } = useAuthApi();
    const { domain } = useAppArgs();
    const { formsService } = useReviewApp();
    const { t } = useTranslation();

    const baseUrl = `https://${authState?.region}.${formsService}.${domain}/api`; // build base url for the FORMS backend at runtime, based on the TENANT's region and domain from the app environemnt (dev/staging/production)

    const httpClient = axios.create({
        headers: {
            Authorization: `Bearer ${authState?.token}`,
            'Content-Type': 'application/json',
        },
    });

    const parseHttpFormErrors = (error: unknown): FormResponse => {
        if (!axios.isAxiosError(error)) {
            return {
                error: { message: t('Unknown Error!') },
            };
        }

        if (error.response) {
            if (error.response.status === 401) {
                setTokenError();
                const apiStatus = error.response.data as ApiStatus;
                apiStatus.message = t(
                    'Your session has expired. Please go to the Dispatcher ScanTrip Cloud portal to log in again.',
                );
                return {
                    error: apiStatus,
                };
            }

            const validateError = error.response.data as ValidateErrorResponse;
            if (validateError) {
                let msg = validateError.message || '';
                if (validateError.details) {
                    const keys = Object.keys(validateError.details);
                    if (keys?.length) {
                        const msgDetails = validateError.details[keys[0]] as ApiStatus;
                        if (msgDetails && msgDetails.message) msg += ` (${msgDetails.message})`;
                    }
                }
                return {
                    error: { code: error.response.status.toString(), message: msg },
                };
            }

            const apiStatus = error.response.data as ApiStatus;
            if (apiStatus) return { error: apiStatus };

            return {
                error: { code: error.response.status.toString(), message: t('Server Error!') },
            };
        }

        if (error.request)
            return {
                error: { message: t('There is no response from the server!') },
            };

        return {
            error: { message: error.message },
        };
    };

    return {
        baseUrl,
        httpClient,
        parseHttpFormErrors,
    } as const;
};
