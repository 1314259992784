import { useDraggable } from '@dnd-kit/core';
import { FormPlugin } from 'contracts/form-plugins-state';
import { FormField } from 'contracts/forms';
import { FormFieldIcon } from './form-field-icon';
import { usePluginsDraggableFieldIds } from 'custom-hooks/use-plugins-draggable-field-ids';

type DraggableFormFieldProps = {
    plugin: FormPlugin;
};

export type DragSourceType = 'fields-panel' | 'fields-preview';

export type FormFieldsDragSourceData = {
    type?: string;
    id?: string;
    index?: number;
    field?: FormField;
    dragSource: DragSourceType;
};

export const DraggableFormField = ({ plugin }: DraggableFormFieldProps) => {
    const draggableFieldIds = usePluginsDraggableFieldIds();
    const { id, type, title, icon } = plugin;
    const { attributes, listeners, setNodeRef } = useDraggable({
        id: draggableFieldIds && draggableFieldIds[type] ? draggableFieldIds[type] : id,
        data: {
            type,
            dragSource: 'fields-panel',
        },
    });

    return (
        <div ref={setNodeRef} {...listeners} {...attributes}>
            <FormFieldIcon icon={icon} title={title} />
        </div>
    );
};
