import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomIcon } from './custom-icon';

export type CustomAccordionProps = {
    title: string;
    titleIcon?: string;
    expanded: boolean;
    children: React.ReactNode;
    className?: string;
    titleClassName?: string;
    detailsClassName?: string;
};

export const CustomAccordion = ({
    title,
    titleIcon = '',
    expanded,
    className = '',
    titleClassName = '',
    detailsClassName = '',
    children,
}: CustomAccordionProps) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    return (
        <Accordion
            expanded={isExpanded}
            onChange={() => setIsExpanded((prev) => !prev)}
            disableGutters
            elevation={0}
            className={`${className}`}
            sx={{
                '&:before': {
                    display: 'none',
                },
            }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="fields-content"
                id="fields-header"
                className={`${titleClassName} m-0`}
                sx={{
                    pl: 0.5,
                    pr: 0,
                }}>
                <div className="flex flex-row">
                    {titleIcon && <CustomIcon src={titleIcon} className="ml-0.5 mr-1.5" />}
                    <Typography variant="subtitle2" className="flex-1 font-semibold whitespace-nowrap">
                        {title}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    pl: 2,
                    pt: 0,
                }}
                className={`${detailsClassName}`}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};
