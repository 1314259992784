import { CustomDropDownOption } from 'components/custom-controls/custom-drop-down';
import { i18n } from 'i18n';

export type FormFieldDateConfig = {
    variable: string;
    title: string;
    defaultValue: string;
    defaultValueLocale?: string;
    minValue: string;
    minValueLocale?: string;
    maxValue: string;
    maxValueLocale?: string;
    returnFormat: string;
    defaultToNow: boolean;
    required: boolean;
    readonly: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText?: string;
};

export const defaultFormFieldDateConfig: FormFieldDateConfig = {
    variable: '',
    title: i18n.t('Date and Time field friendly name goes here'),
    defaultValue: '',
    defaultValueLocale: '',
    minValue: '',
    minValueLocale: '',
    maxValue: '',
    maxValueLocale: '',
    returnFormat: '',
    defaultToNow: false,
    required: false,
    readonly: false,
    excludeFromMetadata: false,
    showHelp: false,
    helpText: '',
};

export const dateTimeFormats: CustomDropDownOption[] = [
    { value: '', label: i18n.t('None') },
    { value: 'yyyyMMdd', label: 'yyyymmdd' },
    { value: 'M/d/yyyy', label: 'm/d/yyyy' },
    { value: 'M/d/yy', label: 'm/d/yy' },
    { value: 'MM/dd/yy', label: 'mm/dd/yy' },
    { value: 'MM/dd/yyyy', label: 'mm/dd/yyyy' },
    { value: 'yy/MM/dd', label: 'yy/mm/dd' },
    { value: 'yyyy-MM-dd', label: 'yyyy-mm-dd' },
    { value: 'dd-MMM-yy', label: 'dd-mmm-yy' },
    { value: 'MM/dd/yy H:mm:ss zzz', label: 'mm/dd/yy h:mm:ss zzz' },
    { value: 'MMMM dd, yyyy', label: 'mmmm dd, yyyy' },
    { value: 'HH:mm:ss', label: 'hh:mm:ss' },
    { value: 'HH:mm:ss aa', label: 'hh:mm:ss aa' },
    { value: 'EEEE, MMMM d, yyyy', label: 'dddd, mmmm d, yyyy' },
    { value: 'EEEE, MMMM d, yyyy H:mm aa', label: 'dddd, mmmm d, yyyy h:mm aa' },
    { value: 'EEEE, MMMM d, yyyy H:mm:ss aa', label: 'dddd, mmmm d, yyyy h:mm:ss aa' },
    { value: 'M/d/yyyy H:mm aa', label: 'm/d/yyyy h:mm aa' },
    { value: 'M/d/yyyy H:mm:ss aa', label: 'm/d/yyyy h:mm:ss aa' },
    { value: 'MMMM d', label: 'mmmm d' },
    { value: 'H:mm aa', label: 'h:mm aa' },
    { value: 'H:mm:ss', label: 'h:mm:ss' },
    { value: 'MMMM yyyy', label: 'mmmm yyyy' },
];
