import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldLabelProperties } from './form-field-label-properties';
import { FormFieldLabelPreview } from './form-field-label-preview';
import { FormFieldLabelConfig, defaultFormFieldLabelConfig } from './form-field-label-config';
import { i18n } from 'i18n';
import labelImage from './assets/icon-form-field-label.svg';

export const FormFieldLabelProvider: FormFieldProvider = {
    type: 'label',
    title: i18n.t('Label'),
    category: 'standard',
    icon: labelImage,
    previewComponent: FormFieldLabelPreview,
    propertiesComponent: FormFieldLabelProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'label',
            id: nanoid(),
            config: { ...defaultFormFieldLabelConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldLabelConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldLabelConfig;
        const errors: string[] = [];
        if (!config.variable) errors.push(i18n.t('Variable name is required'));

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldLabelConfig;
        return config.variable || '';
    },
    getMatadataVariable: () => {
        return '';
    },
    getFormattedValue: () => {
        return null;
    },
};
