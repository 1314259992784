import { useContext } from 'react';
import { DropdownOptionsEditorSetCallbacksContext } from './dropdown-options-editor-context-provider';

export const useDropdownOptionsEditorSetCallbacks = () => {
    const context = useContext(DropdownOptionsEditorSetCallbacksContext);

    if (!context) {
        throw new Error(
            'useDropdownOptionsEditorSetCallbacks has to be used within <DropdownOptionsEditorSetCallbacksContext.Provider>',
        );
    }
    return context;
};
