import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldNumberPreview } from './form-field-number-preview';
import { FormFieldNumberProperties } from './form-field-number-properties';
import { FormFieldNumberConfig, defaultFormFieldNumberConfig } from './form-field-number-config';
import numberImage from './assets/icon-form-field-number.svg';
import { i18n } from 'i18n';

export const FormFieldNumberProvider: FormFieldProvider = {
    type: 'number',
    title: i18n.t('Number'),
    category: 'standard',
    icon: numberImage,
    previewComponent: FormFieldNumberPreview,
    propertiesComponent: FormFieldNumberProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'number',
            id: nanoid(),
            config: { ...defaultFormFieldNumberConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldNumberConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldNumberConfig;
        const errors: string[] = [];
        const hasMin = config.minValue && config.minValue.trim();
        const hasMax = config.maxValue && config.maxValue.trim();
        if (!config.variable) errors.push(i18n.t('Variable name is required.'));
        if (!hasMin) errors.push(i18n.t('Min value is required.'));
        if (!hasMax) errors.push(i18n.t('Max value is required.'));
        if (hasMin && hasMax) {
            const min = parseInt(config.minValue);
            const max = parseInt(config.maxValue);
            if (isNaN(min)) errors.push(i18n.t('Invalid min value.'));
            else if (isNaN(max)) errors.push(i18n.t('Invalid max value.'));
            else if (min > max || max < 1) errors.push(i18n.t('Max value should be greater than min value.'));
            if (config.defaultValue) {
                const regexp = /{[-.\w]+:([\w-]+)}/g;
                const matches = config.defaultValue.match(regexp);
                if (matches === null || matches.length < 1) {
                    const defaultValue = parseFloat(config.defaultValue);
                    if (isNaN(defaultValue))
                        errors.push(i18n.t('The default value field can only contain numeric values.'));
                    else if (!(defaultValue >= min && defaultValue <= max))
                        errors.push(
                            i18n.t(
                                'The default value should be in between the min and max values defined on this field.',
                            ),
                        );
                }
            }
        }
        if (config.required && config.readonly && config.defaultValue.trim() === '') {
            errors.push(
                i18n.t(
                    'A default value is required for this field, because it is designated as both Required and Read only.',
                ),
            );
        }

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldNumberConfig;
        return config.variable || '';
    },
    getMatadataVariable: (field: FormField) => {
        const config = field.config as FormFieldNumberConfig;
        return !config.excludeFromMetadata ? config.variable : '';
    },
    getFormattedValue: () => {
        return null;
    },
};
