import { useRef } from 'react';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldLabelConfig, defaultFormFieldLabelConfig } from './form-field-label-config';

export const useFormFieldLabel = ({ field, updateField }: FormFieldComponentProps) => {
    const configFromField = field.config as FormFieldLabelConfig;
    const config = {
        ...defaultFormFieldLabelConfig,
        ...configFromField,
    };
    const configRef = useRef<FormFieldLabelConfig>(config);

    const onCustomTextFieldChange = (variable: string, value: string) => {
        updateConfig(variable as keyof FormFieldLabelConfig, value);
    };

    const updateConfig = <K extends keyof FormFieldLabelConfig>(key: K, value: FormFieldLabelConfig[K]) => {
        const updatedConfig = {
            ...configRef.current,
        };
        updatedConfig[key] = value;
        configRef.current = updatedConfig;

        const modifiedField = {
            ...field,
        };
        modifiedField.config = updatedConfig;
        updateField(modifiedField);
    };

    return {
        config,
        onCustomTextFieldChange,
    } as const;
};
