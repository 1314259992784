import { AuthRegions } from './auth-context-state';

export enum AuthActionType {
    SET_TOKEN = 'set_token',
    TOKEN_ERROR = 'token_error',
}

export type SetTokenAuthAction = {
    type: AuthActionType.SET_TOKEN;
    payload: {
        token: string;
        region: AuthRegions;
    };
};

export type TokenErrorAuthAction = {
    type: AuthActionType.TOKEN_ERROR;
};

export type AuthAction = SetTokenAuthAction | TokenErrorAuthAction;
