import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldDropdownConfig, defaultFormFieldDropdownConfig } from './form-field-dropdown-config';
import { FormFieldDropdownPreview } from './form-field-dropdown-preview';
import { FormFieldDropdownProperties } from './form-field-dropdown-properties';
import { i18n } from 'i18n';
import dropdownImage from './assets/icon-form-field-dropdown.svg';

export const FormFieldDropdownProvider: FormFieldProvider = {
    type: 'dropdown',
    title: i18n.t('Drop-Down'),
    category: 'standard',
    icon: dropdownImage,
    previewComponent: FormFieldDropdownPreview,
    propertiesComponent: FormFieldDropdownProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'dropdown',
            id: nanoid(),
            config: { ...defaultFormFieldDropdownConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldDropdownConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldDropdownConfig;
        const errors: string[] = [];
        if (!config.variable) errors.push(i18n.t('Variable name is required.'));

        const filteredOptions = config.options.filter((opt) => opt.show);
        if (filteredOptions.length < 1) {
            errors.push(i18n.t('Drop-Down list is empty. Please add or enable one or more drop-down options.'));
        } else {
            const optionsEmptyLabels = filteredOptions.filter((opt) => !opt.label || opt.label.trim() === '');
            if (optionsEmptyLabels.length > 1)
                errors.push(i18n.t('Only one empty label is allowed in the Drop-Down list options.'));

            const optionsEmptyValues = filteredOptions.filter((opt) => !opt.value || opt.value.trim() === '');
            if (optionsEmptyValues.length > 1) {
                errors.push(i18n.t('Only one empty value is allowed in the Drop-Down list options.'));
            } else {
                const uniqueOptionValues = new Set(filteredOptions.map((opt) => opt.value));
                if (uniqueOptionValues.size < filteredOptions.length)
                    errors.push(i18n.t('Duplicate values are not allowed in the Drop-Down list options.'));
            }
        }

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldDropdownConfig;
        return config.variable || '';
    },
    getMatadataVariable: (field: FormField) => {
        const config = field.config as FormFieldDropdownConfig;
        return !config.excludeFromMetadata ? config.variable : '';
    },
    getFormattedValue: () => {
        return null;
    },
};
