import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldDropZonePreview } from './form-field-dropzone-preview';
import { FormFieldDropZoneProperties } from './form-field-dropzone-properties';

export const FormFieldDropZoneProvider: FormFieldProvider = {
    type: 'dropzone',
    title: 'Dropzone',
    category: 'standard',
    icon: '',
    previewComponent: FormFieldDropZonePreview,
    propertiesComponent: FormFieldDropZoneProperties,
    getField: () => {
        return {
            type: 'dropzone',
            id: nanoid(),
            config: {},
        };
    },
    cloneField: (field: FormField) => {
        return field;
    },
    validate: () => {
        return { valid: true };
    },
    getVariable: () => {
        return '';
    },
    getMatadataVariable: () => {
        return '';
    },
    getFormattedValue: () => {
        return null;
    },
};
