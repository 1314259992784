import Divider from '@mui/material/Divider';
import { AppLogo } from './app-logo';
import { AppTitle } from './app-title';
import { AppUserInfo } from './app-user-info';

export const AppHeader = () => {
    return (
        <div className="flex p-2 bg-stc-pattern bg-contain bg-no-repeat bg-[100%_center] bg-blend-soft-light">
            <AppLogo />
            <Divider orientation="vertical" className="bg-white ml-6 mr-6" />
            <AppTitle />
            <AppUserInfo />
        </div>
    );
};

export * from './app-logo';
export * from './app-title';
export * from './app-user-info';
