import { i18n } from 'i18n';

export type FormFieldLabelConfig = {
    variable: string;
    title: string;
};

export const defaultFormFieldLabelConfig: FormFieldLabelConfig = {
    variable: '',
    title: i18n.t('Label goes here'),
};
