import React from 'react';
import { DndContext, DragOverlay, MeasuringConfiguration, getClientRect } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableFormFieldPreview } from 'components/form-canvas-panel';
import { DragOverlayFormField } from 'components/form-fields-panel';
import { useFormDnd } from 'custom-hooks/use-form-dnd';
import { useFormFields } from 'custom-hooks/use-form-fields';

export type FormBuilderDndPanelsProps = {
    fieldsPanel: React.ReactNode;
    canvasPanel: React.ReactNode;
};

export const FormBuilderDndPanels = ({ fieldsPanel, canvasPanel }: FormBuilderDndPanelsProps) => {
    const { activeFormFieldsDragData, onFormFieldsDragStart, onFormFieldsDragOver, onFormFieldsDragEnd } = useFormDnd();
    const fields = useFormFields();

    const measuringConfig: MeasuringConfiguration = {
        draggable: {
            measure: getClientRect,
        },
    };

    return (
        <DndContext
            onDragStart={onFormFieldsDragStart}
            onDragOver={onFormFieldsDragOver}
            onDragEnd={onFormFieldsDragEnd}
            measuring={measuringConfig}
            autoScroll>
            {fieldsPanel}
            <SortableContext strategy={verticalListSortingStrategy} items={fields.map((f) => f.id)}>
                {canvasPanel}
            </SortableContext>

            <DragOverlay>
                {activeFormFieldsDragData?.dragSource === 'fields-panel' ? (
                    <DragOverlayFormField fieldType={activeFormFieldsDragData.type || ''} />
                ) : null}
                {activeFormFieldsDragData?.dragSource === 'fields-preview' && activeFormFieldsDragData.field ? (
                    <SortableFormFieldPreview
                        field={activeFormFieldsDragData.field}
                        id={activeFormFieldsDragData.field.id}
                        index={0}
                        disabled={true}
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
};
