import produce from 'immer';
import { AuthAction, AuthActionType, AuthState } from './auth-context-provider';

export const authContextReducer = produce((state: AuthState, action: AuthAction) => {
    switch (action.type) {
        case AuthActionType.SET_TOKEN: {
            state.isAuthenticated = true;
            state.fetched = true;
            state.token = action.payload.token;
            state.region = action.payload.region;
            return state;
        }
        case AuthActionType.TOKEN_ERROR: {
            state.isAuthenticated = false;
            state.fetched = true;
            state.token = undefined;
            state.region = undefined;

            return state;
        }
        default:
            return state;
    }
});
