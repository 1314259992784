import React, { createContext, useReducer, useRef } from 'react';
import {
    DropdownOptionsEditorAction,
    DropdownOptionsEditorCallbacks,
    DropdownOptionsEditorDispatchOnSave,
    DropdownOptionsEditorState,
    dropdownOptionsEditorContextStateReducer,
} from './dropdown-options-editor-context-state-reducer';

export const DropdownOptionsEditorActionsApiContext = createContext<React.Dispatch<DropdownOptionsEditorAction>>(
    () => null,
);
export const DropdownOptionsEditorItemsContext = createContext<DropdownOptionsEditorState>(
    {} as DropdownOptionsEditorState,
);

export const DropdownOptionsEditorCallbacksContext = createContext<DropdownOptionsEditorCallbacks>(
    {} as DropdownOptionsEditorCallbacks,
);

export const DropdownOptionsEditorSetCallbacksContext = createContext<
    (value: DropdownOptionsEditorDispatchOnSave) => void
>(() => null);

export const DropdownOptionsEditorContextProvider = ({
    initialState,
    initialCallbacks,
    children,
}: {
    initialState: DropdownOptionsEditorState;
    initialCallbacks: DropdownOptionsEditorCallbacks;
    children?: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(dropdownOptionsEditorContextStateReducer, initialState);
    const editorCallbacks = useRef(initialCallbacks);
    const setDispatchOnSaveCallback = ({ dispatchOnSave: disPatchOnSave }: DropdownOptionsEditorDispatchOnSave) => {
        editorCallbacks.current.dispatchOnSave = disPatchOnSave;
    };

    return (
        <DropdownOptionsEditorActionsApiContext.Provider value={dispatch}>
            <DropdownOptionsEditorItemsContext.Provider value={state}>
                <DropdownOptionsEditorCallbacksContext.Provider value={editorCallbacks.current}>
                    <DropdownOptionsEditorSetCallbacksContext.Provider value={setDispatchOnSaveCallback}>
                        {children}
                    </DropdownOptionsEditorSetCallbacksContext.Provider>
                </DropdownOptionsEditorCallbacksContext.Provider>
            </DropdownOptionsEditorItemsContext.Provider>
        </DropdownOptionsEditorActionsApiContext.Provider>
    );
};
