import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldHiddenConfig } from './form-field-hidden-config';

export const FormFieldHiddenPreview = ({ field }: FormFieldComponentProps) => {
    const config = field.config as FormFieldHiddenConfig;

    return (
        <div className="border border-dashed rounded-lg border-gray-400 overflow-hidden">
            <div className="m-3 overflow-hidden break-all">{config.defaultValue}</div>
        </div>
    );
};
