import { AppLoadingMessage } from './app-loading-context-provider';

export enum AppLoadingActionType {
    SHOW = 'show',
    HIDE = 'hide',
}

export type ShowAppLoadingAction = {
    type: AppLoadingActionType.SHOW;
    payload: {
        message: AppLoadingMessage;
    };
};

export type HideAppLoadingAction = {
    type: AppLoadingActionType.HIDE;
};

export type AppLoadingAction = ShowAppLoadingAction | HideAppLoadingAction;
