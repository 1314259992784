import { CustomIconButton } from 'components/custom-controls';
import { useFormActions } from 'custom-hooks/use-form-actions';
import iconClose from './assets/icon_close.svg';
import { useTranslation } from 'react-i18next';

export const FormErrorsCloseButton = () => {
    const { setShowFormErrors } = useFormActions();
    const { t } = useTranslation();

    const onCloseButtonClick = () => {
        setShowFormErrors(false);
    };

    return <CustomIconButton src={iconClose} tooltip={t('Close Error List')} onClick={onCloseButtonClick} />;
};
