import { nanoid } from 'nanoid';
import { FormField, FormFieldProvider } from 'contracts/forms';
import { FormFieldCheckboxPreview } from './form-field-checkbox-preview';
import { FormFieldCheckboxProperties } from './form-field-checkbox-properties';
import { FormFieldCheckboxConfig, defaultFormFieldCheckboxConfig } from './form-field-checkbox-config';
import { i18n } from 'i18n';
import checkMarkImage from './assets/icon-form-field-checkmark.svg';

export const FormFieldCheckboxProvider: FormFieldProvider = {
    type: 'checkbox',
    title: i18n.t('Checkmark'),
    category: 'standard',
    icon: checkMarkImage,
    previewComponent: FormFieldCheckboxPreview,
    propertiesComponent: FormFieldCheckboxProperties,
    getField: (suggestedVarName = '') => {
        return {
            type: 'checkbox',
            id: nanoid(),
            config: { ...defaultFormFieldCheckboxConfig, ...{ variable: suggestedVarName } },
        };
    },
    cloneField: (field: FormField, suggestedVarName = '') => {
        const config = field.config as FormFieldCheckboxConfig;
        config.variable = suggestedVarName || config.variable;
        const newField = { ...field };
        newField.config = { ...config };
        newField.id = nanoid();
        return newField;
    },
    validate: (field: FormField) => {
        const config = field.config as FormFieldCheckboxConfig;
        const errors: string[] = [];
        if (!config.variable) errors.push(i18n.t('Variable name is required.'));
        if (config.buttonsList) {
            if (!config.trueLabel.trim()) errors.push(i18n.t('Checkmark true button label is required.'));
            if (!config.falseLabel.trim()) errors.push(i18n.t('Checkmark false button label is required.'));
        }

        return { valid: errors.length < 1, errors };
    },
    getVariable: (field: FormField) => {
        const config = field.config as FormFieldCheckboxConfig;
        return config.variable || '';
    },
    getMatadataVariable: (field: FormField) => {
        const config = field.config as FormFieldCheckboxConfig;
        return !config.excludeFromMetadata ? config.variable : '';
    },
    getFormattedValue: () => {
        return null;
    },
};
