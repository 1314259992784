import { CSS } from '@dnd-kit/utilities';
import { PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CustomIconButton } from 'components/custom-controls/custom-controls';
import { DropdownOptionsEditorItem } from './dropdown-options-editor-item';
import { FormFieldDropdownOption } from './form-field-dropdown-config';
import dragIcon from 'components/app-assets/icon-dragIndicator.svg';
import { useTranslation } from 'react-i18next';

type DropdownOptionsEditorSortableItemProps = FormFieldDropdownOption & {
    index: number;
    disabled: boolean;
    multiSelect: boolean;
    className?: string;
};

export const DropdownOptionsEditorSortableItem = ({
    id,
    label,
    value,
    show,
    isDefault,
    multiSelect,
    index,
    disabled,
    className = '',
    ...rest
}: PropsWithChildren<DropdownOptionsEditorSortableItemProps>) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
        disabled,
    });
    const { t } = useTranslation();
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} className={`flex flex-row ${className}`}>
            <div {...listeners}>
                <CustomIconButton
                    src={dragIcon}
                    tooltip={disabled ? '' : t('Drag to reorder')}
                    tooltipPlacement="left"
                    className="cursor-move -ml-2 mt-2"
                />
            </div>
            <DropdownOptionsEditorItem
                id={id}
                label={label}
                value={value}
                show={show}
                isDefault={isDefault}
                multiSelect={multiSelect}
                {...rest}
                className="mt-2 mb-2"
            />
        </div>
    );
};
