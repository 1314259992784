import formIcon from './assets/form-icon.png';

export const AppTitle = () => {
    return (
        <div className="flex items-center justify-center">
            <img src={formIcon} className="mr-3 mt-1" alt="Form Builder" />
            <h1 className="text-2xl text-white">Form Builder</h1>
        </div>
    );
};
