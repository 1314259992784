import { Button } from '@mui/material';
import { useDropdownOptionsEditorCallbacks } from './use-dropdown-options-editor-callbacks';
import { useTranslation } from 'react-i18next';

export type DropdownOptionsDialogActionsProps = {
    className?: string;
};

export const DropdownOptionsDialogActions = ({ className = '' }: DropdownOptionsDialogActionsProps) => {
    const editorCallbacks = useDropdownOptionsEditorCallbacks();
    const { t } = useTranslation();

    return (
        <div className={`${className}`}>
            <Button
                variant="contained"
                onClick={() => {
                    if (editorCallbacks.onCancel) editorCallbacks.onCancel();
                }}>
                {t('Cancel')}
            </Button>
            <Button
                className="ml-6"
                variant="contained"
                onClick={() => {
                    if (editorCallbacks.dispatchOnSave) editorCallbacks.dispatchOnSave();
                }}>
                {t('Save')}
            </Button>
        </div>
    );
};
