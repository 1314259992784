import { useState } from 'react';
import { FormPropertiesCollapsedPanel } from './form-properties-collapsed-panel';
import { FormPropertiesExpandedPanel } from './form-properties-expanded-panel';

export const FormPropertiesPanel = () => {
    const [isPinned, setIsPinned] = useState(true);

    const togglePinButtonState = () => {
        setIsPinned((prev) => !prev);
    };

    if (!isPinned) return <FormPropertiesCollapsedPanel onPinButtonClick={togglePinButtonState} />;

    return <FormPropertiesExpandedPanel onPinButtonClick={togglePinButtonState} />;
};

export * from './form-properties-collapsed-panel';
export * from './form-properties-empty-stage';
export * from './form-properties-expanded-panel';
