import { memo } from 'react';
import {
    CustomIconButton,
    CustomOutlinedInput,
    CustomIconToggleButton,
    CustomCheckbox,
} from 'components/custom-controls';
import { FormFieldDropdownOption } from './form-field-dropdown-config';
import { useDropdownOptionsEditorActions } from './use-dropdown-options-editor-actions';
import viewIcon from './assets/view-icon.svg';
import hideIcon from './assets/hide-visibility.svg';
import radioButtonOnIcon from './assets/radio-button-checked.svg';
import radioButtonOffIcon from './assets/radio-button-unchecked.svg';
import deleteIcon from './assets/delete-icon.svg';
import { useTranslation } from 'react-i18next';

type DropdownOptionsEditorItemProps = FormFieldDropdownOption & {
    multiSelect: boolean;
    className?: string;
};

const DropdownOptionsEditorItemBase = ({
    id,
    label,
    value,
    show,
    isDefault,
    multiSelect,
    className = '',
}: DropdownOptionsEditorItemProps) => {
    const { updateItem, deleteItem } = useDropdownOptionsEditorActions();
    const { t } = useTranslation();

    const onCustomOutlinedInputChange = (variable: string, value: string) => {
        updateOption(variable as keyof FormFieldDropdownOption, value);
    };

    const onCustomToggleIconButtonChange = (variable: string, value: boolean) => {
        updateOption(variable as keyof FormFieldDropdownOption, value);
    };

    const updateOption = <K extends keyof FormFieldDropdownOption>(
        key: K,
        updatedValue: FormFieldDropdownOption[K],
    ) => {
        const opt: FormFieldDropdownOption = {
            id,
            label,
            value,
            show,
            isDefault,
        };
        opt[key] = updatedValue;
        updateItem(opt);
    };

    return (
        <div className={`flex flex-row ${className}`}>
            <CustomIconToggleButton
                variable="show"
                defaultValue={show}
                toggleOnIcon={viewIcon}
                toggleOffIcon={hideIcon}
                toggleOnAltText={t('Hide this option from the drop-down')}
                toggleOffAltText={t('Show this option in the drop-down')}
                onChange={onCustomToggleIconButtonChange}
            />

            {!multiSelect && (
                <CustomIconToggleButton
                    variable="isDefault"
                    defaultValue={isDefault && show}
                    disabled={!show}
                    toggleOnIcon={radioButtonOnIcon}
                    toggleOffIcon={radioButtonOffIcon}
                    toggleOnAltText={t('Unselect to clear the default value')}
                    toggleOffAltText={t('Select to set as default value')}
                    onChange={onCustomToggleIconButtonChange}
                />
            )}

            {multiSelect && (
                <CustomCheckbox
                    variable="isDefault"
                    label=""
                    defaultValue={isDefault && show}
                    disabled={!show}
                    toggleOnAltText={t('Unselect to clear the default value')}
                    toggleOffAltText={t('Select to set as default value')}
                    onChange={onCustomToggleIconButtonChange}
                />
            )}

            <CustomOutlinedInput
                variable="label"
                label={t('Label')}
                defaultValue={label}
                maxLength={100}
                className="ml-2 bg-white"
                onChange={onCustomOutlinedInputChange}
            />

            <CustomOutlinedInput
                variable="value"
                label={t('Value')}
                defaultValue={value}
                maxLength={100}
                className="ml-4 bg-white"
                onChange={onCustomOutlinedInputChange}
            />

            <CustomIconButton
                src={deleteIcon}
                className="ml-3"
                tooltip={t('Delete')}
                tooltipPlacement="right"
                onClick={() => {
                    deleteItem(id);
                }}
            />
        </div>
    );
};

export const DropdownOptionsEditorItem = memo(DropdownOptionsEditorItemBase);
