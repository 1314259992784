import { nanoid } from 'nanoid';
import { FormBuilder } from 'components/form-builder';
import { FormContextProvider, INITIAL_FORM_STATE } from 'context-providers/form-context-provider';
import { FormPluginsContextProvider, PLUGIN_PROVIDERS } from 'context-providers/form-plugins-context-provider';
import { FormPlugin } from 'contracts/form-plugins-state/form-plugin';
import { FormPluginsState } from 'contracts/form-plugins-state/form-plugins-state';
import { useQuery } from '@tanstack/react-query';
import { useHttpLoadForm } from 'custom-hooks/use-http-load-form';
import { useAppLoadingApi } from 'custom-hooks/use-app-loading-api';
import { useSleep } from 'custom-hooks/use-sleep';
import { useAppArgs } from 'custom-hooks/use-app-args';

export const App = () => {
    const { formId, tenantSlug } = useAppArgs();
    const { hideLoading } = useAppLoadingApi();
    const { sleep } = useSleep();
    const { loadForm } = useHttpLoadForm();
    const { data, isLoading } = useQuery({
        queryKey: ['load-form'],
        queryFn: loadForm,
        refetchOnWindowFocus: false,
    });

    if (!tenantSlug) {
        hideLoading();
        return <h5>Access Denied!</h5>;
    }

    if (isLoading) {
        return <></>;
    }

    const formState = { ...INITIAL_FORM_STATE };
    if (data && data.data?.formDefinition) {
        formState.form.title = data.data.title;
        if (formId) {
            formState.showNewForm = false;
            formState.form.id = formId;
            if (data.data.formGroup && data.data.formGroup.id) formState.form.groupId = data.data.formGroup.id;
        }
        if (data.data.formDefinition) {
            if (data.data.formDefinition.targetMfpId) formState.targetMfpId = data.data.formDefinition.targetMfpId;
            if (data.data.formDefinition.fields) formState.form.fields = data.data.formDefinition.fields;
        }
    }

    const plugins: FormPlugin[] = [];
    const draggableFieldIds: { [key: string]: string } = {};

    PLUGIN_PROVIDERS.forEach((p) => {
        if (p.type !== 'dropzone') {
            const id = nanoid();
            plugins.push({
                id: id,
                type: p.type,
                icon: p.icon,
                title: p.title,
            });
            draggableFieldIds[p.type] = id;
        }
        if (p.type === 'pagebreak' && formState.form.fields.length < 1) {
            const page1 = p.getField('page1');
            page1.pageNumber = 1;
            page1.totalPages = 1;
            formState.form.fields.push(page1);
        }
    });

    const pluginsInitialState: FormPluginsState = {
        providers: PLUGIN_PROVIDERS,
        plugins,
        draggableFieldIds,
    };

    sleep(1).then(() => {
        hideLoading();
    });

    return (
        <FormPluginsContextProvider initialState={pluginsInitialState}>
            <FormContextProvider initialFormState={formState}>
                <FormBuilder />
            </FormContextProvider>
        </FormPluginsContextProvider>
    );
};
