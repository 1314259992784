import { useContext } from 'react';
import { AuthContext } from 'context-providers/auth-context-provider';

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth has to be used within <AuthContext.Provider>');
    }

    return context;
};
