import { TextField } from '@mui/material';

export type CustomReadOnlyTextFieldProps = {
    variable?: string;
    label: string;
    defaultValue?: string;
    className?: string;
};

export const CustomReadOnlyTextField = ({ label, defaultValue = '', className = '' }: CustomReadOnlyTextFieldProps) => {
    return (
        <TextField
            label={label}
            size="small"
            className={`${className}`}
            value={defaultValue || ''}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true }}
        />
    );
};
