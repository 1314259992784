import produce from 'immer';
import { nanoid } from 'nanoid';
import { FormPluginsAction, FormPluginsActionType, FormPluginsState } from 'contracts/form-plugins-state';

export const formPluginsStateReducer = produce((state: FormPluginsState, action: FormPluginsAction) => {
    switch (action.type) {
        case FormPluginsActionType.RESET_DND_KEY: {
            if (action.payload.pluginType && state.draggableFieldIds[action.payload.pluginType])
                state.draggableFieldIds[action.payload.pluginType] = nanoid();
            return state;
        }
        default:
            return state;
    }
});
