import { useContext } from 'react';
import { FormErrorsContext } from 'context-providers/form-context-provider';

export const useFormErrors = () => {
    const context = useContext(FormErrorsContext);

    if (!context) {
        throw new Error('useFormErrors has to be used within <FormErrorsContext.Provider>');
    }

    return context;
};
