import { TextField } from '@mui/material';
import { useState } from 'react';

export type CustomVariableNameFieldProps = {
    variable?: string;
    label: string;
    defaultValue: string;
    maxLength?: number;
    className?: string;
    onChange?: (variable: string, value: string) => void;
};

export const CustomVariableNameField = ({
    variable,
    label,
    defaultValue,
    maxLength = 0,
    className = '',
    onChange,
}: CustomVariableNameFieldProps) => {
    const [controlValue, setControlValue] = useState(defaultValue);

    return (
        <TextField
            label={label}
            size="small"
            className={`${className}`}
            defaultValue={controlValue}
            InputLabelProps={{ shrink: true }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setControlValue(event.target.value);
                if (onChange) onChange(variable || '', event.target.value);
            }}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                let value = event.target.value.toString().replace(/[^0-9A-Za-z_]/g, '');
                if (maxLength) if (value.length > maxLength) value = value.slice(0, maxLength);
                event.target.value = value;
            }}
        />
    );
};
