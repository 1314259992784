import { useAppArgs } from 'custom-hooks/use-app-args';
import { useAppLoadingApi } from 'custom-hooks/use-app-loading-api';
import { useAuthApi } from 'custom-hooks/use-auth-api';

export const AuthRedirect = () => {
    const { handleAuth } = useAuthApi();
    const { tenantSlug } = useAppArgs();
    const { hideLoading } = useAppLoadingApi();

    if (!tenantSlug) {
        hideLoading();
        return <h5>Access Denied!</h5>;
    }

    handleAuth();

    return <></>;
};
