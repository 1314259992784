import { AppSnackbarMessage, AppSnackbarMessageType } from './';

export enum AppSnackbarActionType {
    SHOW = 'show',
    HIDE = 'hide',
}

export type ShowAppSnackbarAction = {
    type: AppSnackbarActionType.SHOW;
    payload: {
        message: AppSnackbarMessage;
        type: AppSnackbarMessageType;
    };
};

export type HideAppSnackbarAction = {
    type: AppSnackbarActionType.HIDE;
};

export type AppSnackbarAction = ShowAppSnackbarAction | HideAppSnackbarAction;
