import { useImmer } from 'use-immer';
import { MfpPanelIcon } from './mfp-panel-icon';
import { MfpPanelItem } from './mfp-panel-item';

type MfpPanelIconsListProps = {
    items: MfpPanelItem[];
    onListChange: (id: string) => void;
};

export const MfpPanelIconsList = ({ items, onListChange }: MfpPanelIconsListProps) => {
    const [mfpPanelItems, setMfpPanelItems] = useImmer(items);
    const selectMfpPanelIcon = (id: string) => {
        setMfpPanelItems((draft) => {
            draft.forEach((item) => {
                item.selected = item.id === id;
                if (item.selected) onListChange(item.id);
            });
        });
    };

    return (
        <div className="flex flex-row">
            {mfpPanelItems.map((item) => (
                <MfpPanelIcon item={item} selectMfpPanelIcon={selectMfpPanelIcon} key={item.id} />
            ))}
        </div>
    );
};
