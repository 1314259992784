import { FormFieldComponentProps } from 'contracts/forms';
import { useFormFieldNumber } from './use-form-field-number';
import {
    CustomAccordion,
    CustomAddHelpText,
    CustomCheckbox,
    CustomNumberField,
    CustomTextField,
    CustomVariableNameField,
} from 'components/custom-controls';
import { useTranslation } from 'react-i18next';

export const FormFieldNumberProperties = (props: FormFieldComponentProps) => {
    const { config, onCustomTextFieldChange, onCustomCheckboxChange } = useFormFieldNumber(props);
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <CustomVariableNameField
                variable="variable"
                label={t('Variable Name')}
                defaultValue={config['variable']}
                maxLength={50}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="title"
                label={t('Friendly Name (Viewable)')}
                defaultValue={config['title']}
                maxLength={100}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="defaultValue"
                label={t('Default Value')}
                defaultValue={config['defaultValue']}
                maxLength={50}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <div className="flex flex-row">
                <CustomNumberField
                    variable="minValue"
                    label={t('Min Value')}
                    defaultValue={config['minValue']}
                    maxLength={10}
                    className="mb-4 mr-1"
                    onChange={onCustomTextFieldChange}
                />
                <CustomNumberField
                    variable="maxValue"
                    label={t('Max Value')}
                    defaultValue={config['maxValue']}
                    maxLength={10}
                    className="mb-4 ml-1"
                    onChange={onCustomTextFieldChange}
                />
            </div>

            <CustomAccordion title={t('Actions')} expanded={true}>
                <div className="flex flex-col">
                    <CustomCheckbox
                        variable="required"
                        label={t('Required')}
                        defaultValue={config['required']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="readonly"
                        label={t('Read only')}
                        defaultValue={config['readonly']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="mask"
                        label={t('Mask input')}
                        defaultValue={config['mask']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="excludeFromMetadata"
                        label={t('Exclude from metadata')}
                        defaultValue={config['excludeFromMetadata']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomAddHelpText
                        checkboxVariable="showHelp"
                        checkboxLabel={t('Add help text')}
                        checkboxDefaultValue={config['showHelp']}
                        onCheckboxChange={onCustomCheckboxChange}
                        txtVariable="helpText"
                        txtLabel={t('Edit help text')}
                        txtDefaultValue={config['helpText'] || ''}
                        onTxtChange={onCustomTextFieldChange}
                    />
                </div>
            </CustomAccordion>
        </div>
    );
};
