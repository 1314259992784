import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { CustomIcon } from 'components/custom-controls';
import { useFormActions } from 'custom-hooks/use-form-actions';
import iconError from './assets/icon_error.svg';

type FormErrorListItemProps = {
    id?: string;
    title: string;
};

export const FormErrorListItem = ({ id, title }: FormErrorListItemProps) => {
    const { selectField } = useFormActions();

    const onErrorItemClick = () => {
        if (id) selectField(id);
    };

    return (
        <ListItemButton
            onClick={onErrorItemClick}
            className={`border-b border-solid border-gray-300 ${!id ? 'cursor-default' : ''}`}>
            <ListItemIcon className="min-w-0 mr-3">
                <CustomIcon src={iconError} />
            </ListItemIcon>
            <ListItemText primary={title} />
        </ListItemButton>
    );
};
