import { memo } from 'react';
import { FormField } from 'contracts/forms';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { usePlugins } from 'custom-hooks/use-plugins';

type FormFieldPreviewProps = {
    field: FormField;
};

const FormFieldPreviewBase = ({ field }: FormFieldPreviewProps) => {
    const { getFieldPreview } = usePlugins();
    const { updateField } = useFormActions();
    const FieldPreviewComponent = getFieldPreview(field.type);

    return (
        <div className="flex-1 relative z-0 p-2 bg-white rounded-lg">
            <FieldPreviewComponent field={field} updateField={updateField} />
            <div className="absolute inset-0 flex justify-center items-center z-10"></div>
        </div>
    );
};

export const FormFieldPreview = memo(FormFieldPreviewBase);
