import { arrayMove } from '@dnd-kit/sortable';
import produce from 'immer';
import { FormAction, FormActionType, FormState } from 'contracts/form-state';

export const formStateReducer = produce((state: FormState, action: FormAction) => {
    const resetPageNumbers = () => {
        const pages = state.form.fields.filter((field) => field.type === 'pagebreak');
        const totalPages = pages.length;
        if (totalPages < 1) return;

        let pageNumber = 0;
        state.form.fields.forEach((f, index) => {
            if (f.type === 'pagebreak') {
                ++pageNumber;
                state.form.fields[index] = { ...state.form.fields[index], ...{ pageNumber, totalPages } };
            }
        });
    };

    switch (action.type) {
        case FormActionType.INSERT_FIELD: {
            if (action.payload.index >= 0 && action.payload.index < state.form.fields.length)
                state.form.fields.splice(action.payload.index, 0, action.payload.field);
            else state.form.fields.push(action.payload.field);
            if (action.payload.field.type !== 'dropzone') resetPageNumbers();
            return state;
        }
        case FormActionType.UPDATE_FIELD: {
            const index = state.form.fields.findIndex((field) => field.id === action.payload.field.id);
            if (index >= 0) state.form.fields[index].config = action.payload.field.config;
            return state;
        }
        case FormActionType.MOVE_FIELD: {
            const oldIndex = state.form.fields.findIndex((field) => field.id === action.payload.id);
            if (oldIndex >= 0 && action.payload.index >= 0 && action.payload.index < state.form.fields.length)
                state.form.fields.splice(action.payload.index, 0, state.form.fields.splice(oldIndex, 1)[0]);
            resetPageNumbers();
            return state;
        }
        case FormActionType.DELETE_FIELD: {
            state.form.fields = state.form.fields.filter((field) => field.id !== action.payload.id);
            resetPageNumbers();
            return state;
        }
        case FormActionType.REMOVE_DROP_ZONES: {
            state.form.fields = state.form.fields.filter((field) => field.type !== 'dropzone');
            return state;
        }
        case FormActionType.REPLACE_FIELD: {
            state.form.fields.splice(action.payload.index, 1, action.payload.field);
            resetPageNumbers();
            return state;
        }
        case FormActionType.SWAP_FIELDS: {
            state.form.fields = arrayMove(state.form.fields, action.payload.from, action.payload.to);
            resetPageNumbers();
            return state;
        }
        case FormActionType.RESET_PAGE_NUMBERS: {
            resetPageNumbers();
            return state;
        }
        case FormActionType.SELECT_FIELD: {
            state.selectedField = null;
            state.form.fields.forEach((f) => {
                if (f.id === action.payload.id) {
                    f.selected = true;
                    state.selectedField = {
                        ...f,
                    };
                } else {
                    f.selected = false;
                }
            });
            return state;
        }
        case FormActionType.NEW_FORM: {
            state.form.title = action.payload.title;
            state.targetMfpId = action.payload.targetMfpId;
            state.sampleFormId = action.payload.sampleFormId;
            state.showNewForm = false;
            return state;
        }
        case FormActionType.SAMPLE_FORM: {
            state.form.title = action.payload.title;
            state.targetMfpId = action.payload.targetMfpId;
            state.sampleFormId = action.payload.sampleFormId;
            if (action.payload.formDetails.formDefinition?.fields) {
                state.form.fields = [];
                action.payload.formDetails.formDefinition.fields.forEach((f) => {
                    state.form.fields.push(f);
                });
                resetPageNumbers();
            }
            state.showNewForm = false;
            return state;
        }
        case FormActionType.SET_TITLE: {
            state.form.title = action.payload;
            return state;
        }
        case FormActionType.SET_ERRORS: {
            state.errors = action.payload;
            state.form.valid = !(action.payload && action.payload.groups && action.payload.groups.length > 0);
            return state;
        }
        case FormActionType.SET_SHOW_ERRORS: {
            state.errors.show = action.payload;
            return state;
        }
        default:
            return state;
    }
});
