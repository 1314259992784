import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#663f80',
            dark: '#231231',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#a48adb',
        },
        background: {
            default: '#efefef',
        },
        action: {
            selected: '#a48adb',
            selectedOpacity: 0.5,
        },
    },
    typography: {
        allVariants: {
            textTransform: 'none',
        },
        fontFamily: [
            '"Open Sans"',
            'Muli',
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Noto Color Emoji"',
        ].join(','),
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#794DA6',
                    color: '#f2eff9',
                    border: '1px solid #fff',
                    borderRadius: 5,
                },
                arrow: {
                    color: '#794DA6',
                    '&::before': {
                        border: '1px solid #f2eff9',
                        backgroundColor: '#794DA6',
                        boxSizing: 'border-box',
                    },
                },
                tooltipPlacementTop: {
                    top: '10px',
                },
                tooltipPlacementBottom: {
                    top: '-14px',
                },
                tooltipPlacementLeft: {
                    left: '10px',
                },
                tooltipPlacementRight: {
                    left: '-14px',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: '8px',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: '8px',
                    },
                },
            },
        },
    },
});
