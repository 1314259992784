import { FormListItem } from 'contracts/forms';
import { useHttp } from 'custom-hooks/use-http';

export const useHttpLoadFormsList = () => {
    const { baseUrl, httpClient } = useHttp();

    const loadFormsList = async (): Promise<FormListItem[]> => {
        const url = `${baseUrl}/forms`;
        const response = await httpClient.get(url);
        return response.data as FormListItem[];
    };

    return {
        loadFormsList,
    } as const;
};
