import { useImmer } from 'use-immer';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { useAppArgs } from 'custom-hooks/use-app-args';
import { NewFormOptions } from 'contracts/form-state';
import { MfpPanelItem } from './mfp-panel-item';
import { useHttpLoadSampleForm } from 'custom-hooks/use-http-load-sample-form/use-http-load-sample-form';
import { useTranslation } from 'react-i18next';

export const useNewForm = () => {
    const [newFormOptions, setNewFormOptions] = useImmer<NewFormOptions>({
        title: '',
        targetMfpId: 'mfp_800x480',
        sampleFormId: 'blank',
    });
    const { createNewForm, createSampleForm } = useFormActions();
    const { redirectUrl } = useAppArgs();
    const { loadSampleForm } = useHttpLoadSampleForm();
    const { t } = useTranslation();

    const mfpPanelSizes: MfpPanelItem[] = [
        {
            id: 'mfp_800x480',
            title: t('MFP 800 x 480'),
            selected: true,
        },
        {
            id: 'mfp_902x530',
            title: t('MFP 902 x 530'),
            selected: false,
        },
        {
            id: 'mfp_1024x585',
            title: t('MFP 1024 x 585'),
            selected: false,
        },
        {
            id: 'mfp_1024x600',
            title: t('MFP 1024 x 600'),
            selected: false,
        },
    ];

    const sampleForms: MfpPanelItem[] = [
        {
            id: 'blank',
            title: t('Blank'),
            selected: true,
        },
        {
            id: 'sample-001',
            title: t('Custom Email'),
            selected: false,
        },
        {
            id: 'sample-002',
            title: t('Destination Selection'),
            selected: false,
        },
        {
            id: 'sample-003',
            title: t('Email Dropdown'),
            selected: false,
        },
    ];

    const updateNewFormOptions = <K extends keyof NewFormOptions>(key: K, value: NewFormOptions[K]) => {
        setNewFormOptions((draft) => {
            draft[key] = value;
        });
    };

    const onTargetMfpChange = (id: string) => {
        updateNewFormOptions('targetMfpId', id);
    };

    const onSampleFormChange = (id: string) => {
        updateNewFormOptions('sampleFormId', id);
    };

    const startNewForm = async () => {
        if (newFormOptions.sampleFormId === 'blank') {
            // create blank form
            createNewForm(newFormOptions);
            return;
        }

        // use same group id for to load the samples until we have more samples created for all the supported MFP groups.
        const resp = await loadSampleForm('mfp_800x480', newFormOptions.sampleFormId);
        if (resp.data) {
            createSampleForm({
                formDetails: resp.data,
                ...newFormOptions,
            });
            return;
        }

        // fallback, create blank form
        createNewForm(newFormOptions);
    };

    const backToFormsManagement = () => {
        window.location.replace(redirectUrl);
    };

    return {
        mfpPanelSizes,
        sampleForms,
        newFormOptions,
        onTargetMfpChange,
        onSampleFormChange,
        updateNewFormOptions,
        startNewForm,
        backToFormsManagement,
    } as const;
};
