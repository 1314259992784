import { FormDetails, FormResponse } from 'contracts/forms';
import { useHttp } from 'custom-hooks/use-http';

export const useHttpLoadSampleForm = () => {
    const { baseUrl, httpClient, parseHttpFormErrors } = useHttp();

    const loadSampleForm = async (groupId: string, formId: string): Promise<FormResponse> => {
        const url = `${baseUrl}/sample-forms/${groupId}/${formId}`;
        try {
            const response = await httpClient.get(url);
            const formDetails = response.data as FormDetails;

            return { data: formDetails };
        } catch (error) {
            return parseHttpFormErrors(error);
        }
    };

    return {
        loadSampleForm,
    } as const;
};
