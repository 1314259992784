import React, { useMemo, useReducer } from 'react';
import {
    AppSnackbarMessage,
    AppSnackbarState,
    AppSnackbarAction,
    AppSnackbarActionType,
    ShowAppSnackbarAction,
    HideAppSnackbarAction,
    appSnackbarStateReducer,
} from './';

type AppSnackbarActionsApi = {
    showSuccessMessage: (message: AppSnackbarMessage) => void;
    showErrorMessage: (message: AppSnackbarMessage) => void;
    hideMessage: () => void;
};

export type FormContextType = {
    state: AppSnackbarState;
    dispatch: (action: AppSnackbarAction) => void;
};

export const AppSnackbarContext = React.createContext<FormContextType>({} as FormContextType);
export const AppSnackbarApiContext = React.createContext<AppSnackbarActionsApi>({} as AppSnackbarActionsApi);

export const AppSnackbarContextProvider = ({
    initialState,
    children,
}: {
    initialState: AppSnackbarState;
    children?: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(appSnackbarStateReducer, initialState);

    const snackbarApi = useMemo(() => {
        const showSuccessMessage = (message: AppSnackbarMessage) => {
            const action: ShowAppSnackbarAction = {
                type: AppSnackbarActionType.SHOW,
                payload: {
                    message,
                    type: 'success',
                },
            };
            dispatch(action);
        };

        const showErrorMessage = (message: AppSnackbarMessage) => {
            const action: ShowAppSnackbarAction = {
                type: AppSnackbarActionType.SHOW,
                payload: {
                    message,
                    type: 'error',
                },
            };
            dispatch(action);
        };

        const hideMessage = () => {
            const action: HideAppSnackbarAction = {
                type: AppSnackbarActionType.HIDE,
            };
            dispatch(action);
        };

        return {
            showSuccessMessage,
            showErrorMessage,
            hideMessage,
        } as const;
    }, []);

    return (
        <AppSnackbarApiContext.Provider value={snackbarApi}>
            <AppSnackbarContext.Provider
                value={{
                    state,
                    dispatch,
                }}>
                {children}
            </AppSnackbarContext.Provider>
        </AppSnackbarApiContext.Provider>
    );
};

export * from './app-snackbar-state-reducer';
export * from './app-snackbar-state';
export * from './app-snackbar-action';
