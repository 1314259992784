import { FormDetails, FormRequest, FormResponse } from 'contracts/forms';
import { useAppArgs } from 'custom-hooks/use-app-args';
import { useForm } from 'custom-hooks/use-form';
import { useFormHelpers } from 'custom-hooks/use-form-helpers';
import { useHttp } from 'custom-hooks/use-http';
import { useTranslation } from 'react-i18next';

export const useHttpSaveForm = () => {
    const { baseUrl, httpClient, parseHttpFormErrors } = useHttp();
    const { state } = useForm();
    const { validateForm, getAllMetadataKeys } = useFormHelpers();
    const { groupId } = useAppArgs();
    const { t } = useTranslation();

    const saveForm = async (): Promise<FormResponse> => {
        const url = `${baseUrl}/forms` + (state.form.id ? `/${state.form.id}` : '');

        if (!state.form.title || !state.form.title.trim()) {
            return {
                error: { message: t('Form name is required.') },
            };
        }

        // validate form before saving to set the valid flag.
        const groups = validateForm();
        const valid = groups.length < 1;
        const metadataKeys = getAllMetadataKeys();

        const data: FormRequest = {
            title: state.form.title,
            valid,
            metadataKeys,
            formDefinition: {
                targetMfpId: state.targetMfpId,
                fields: [],
            },
        };

        // for new form request's, add form group if group id exists.
        if (groupId && !(state.form.id && state.form.id.length > 0)) {
            data.formGroup = {
                id: groupId,
            };
        } else if (state.form.groupId && state.form.id) {
            // in case the Form is deleted by someone else while editing,
            //  - retain the existing form's group id to use it to re-create the form in the same group when the form id doesn't exist.
            data.formGroup = {
                id: state.form.groupId,
            };
        }

        // copy all field props except selected property.
        state.form.fields.forEach((f) => {
            const { selected, pageNumber, totalPages, ...rest } = f;
            data.formDefinition?.fields?.push(rest);
        });

        try {
            const response = state.form.id ? await httpClient.put(url, data) : await httpClient.post(url, data);
            return { data: response.data as FormDetails };
        } catch (error) {
            return parseHttpFormErrors(error);
        }
    };

    return {
        saveForm,
    } as const;
};
