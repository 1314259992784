import { nanoid } from 'nanoid';
import { Alert, AlertTitle, Slide, SlideProps, Snackbar } from '@mui/material';
import { useAppSnackbar } from 'custom-hooks/use-app-snackbar';
import { useAppSnackbarApi } from 'custom-hooks/use-app-snackbar-api';

export const AppSnackbar = () => {
    const { state } = useAppSnackbar();
    const { hideMessage } = useAppSnackbarApi();

    const handleClose = () => {
        hideMessage();
    };

    function TransitionRight(props: JSX.IntrinsicAttributes & SlideProps) {
        return <Slide {...props} direction="right" />;
    }

    return (
        <Snackbar
            key={nanoid()}
            open={state.show}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            TransitionComponent={TransitionRight}>
            <Alert onClose={handleClose} variant="filled" severity={state.messageType} sx={{ width: '100%' }}>
                {state.message.title && <AlertTitle>{state.message.title}</AlertTitle>}
                {state.message.text}
            </Alert>
        </Snackbar>
    );
};
