import { useContext } from 'react';
import { FormActionsApiContext } from 'context-providers/form-context-provider';

export const useFormActions = () => {
    const context = useContext(FormActionsApiContext);

    if (!context) {
        throw new Error('useFormActions has to be used within <FormApiContext.Provider>');
    }

    return context;
};
