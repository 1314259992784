import { Checkbox, FormControlLabel, FormGroup, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CustomHelpIcon } from 'components/custom-controls';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldCheckboxConfig } from './form-field-checkbox-config';

export const FormFieldCheckboxPreview = ({ field }: FormFieldComponentProps) => {
    const config = field.config as FormFieldCheckboxConfig;
    if (!config.buttonsList) {
        return (
            <div className="flex flex-row">
                <FormGroup className="flex-1 break-all">
                    <FormControlLabel
                        control={<Checkbox size="small" className="pt-0 pb-0" checked={config.checked} />}
                        label={config.title}
                        className="break-all"
                    />
                </FormGroup>
                {config.showHelp && <CustomHelpIcon className="ml-3" />}
            </div>
        );
    }

    const defaultValue = config.checked ? 'True' : '';
    return (
        <div className="flex flex-row">
            <div className="flex-1 items-center justify-center break-all">
                <span>{config.title}</span>
            </div>
            <ToggleButtonGroup
                color="primary"
                value={defaultValue}
                exclusive
                aria-label="CheckboxButtons"
                className="flex-1 ml-12">
                <ToggleButton value="True" className="max-w-[200px] pl-4 pr-4 overflow-hidden break-words">
                    {config.trueLabel}
                </ToggleButton>
                <ToggleButton value="False" className="max-w-[200px] pl-4 pr-4 overflow-hidden break-words">
                    {config.falseLabel}
                </ToggleButton>
            </ToggleButtonGroup>
            {config.showHelp && <CustomHelpIcon className="ml-3" />}
        </div>
    );
};
