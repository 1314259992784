import { AccountCircle } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { useHttpLoadUserInfo } from 'custom-hooks/use-http-load-user-info';
import { useTranslation } from 'react-i18next';

export const AppUserInfo = () => {
    const { loadUserInfo } = useHttpLoadUserInfo();
    const { t } = useTranslation();
    const { data } = useQuery({
        queryKey: ['load-form'],
        queryFn: loadUserInfo,
        refetchOnWindowFocus: false,
    });
    const userName = data?.data?.givenName || '';
    return (
        <div className="flex-1 m-auto pr-2">
            <div className="flex flex-row justify-end">
                <AccountCircle fontSize="large" className="text-white mr-3" />
                <div className="flex items-center justify-center">
                    <h1 className="text-1xl font-normal text-white">
                        {t('Welcome')}, {userName}
                    </h1>
                </div>
            </div>
        </div>
    );
};
