import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export type CustomDropDownOption = {
    label: string;
    value: string;
};

export type CustomDropDownProps = {
    variable?: string;
    label: string;
    defaultValue?: string;
    options: CustomDropDownOption[];
    className?: string;
    onChange?: (variable: string, value: string) => void;
};

export const CustomDropDown = ({
    variable,
    label,
    defaultValue = '',
    options = [],
    className = '',
    onChange,
}: CustomDropDownProps) => {
    const [controlValue, setControlValue] = useState(defaultValue || '');
    const dorpdownItems = options.map((opt, index) => (
        <MenuItem value={opt.value || ''} key={index}>
            {opt.label}
        </MenuItem>
    ));

    const onSelectionChange = (event: SelectChangeEvent) => {
        setControlValue(event.target.value);
        if (onChange) onChange(variable || '', event.target.value);
    };

    return (
        <FormControl sx={{ m: 0, minWidth: 40 }} size="small" className={`${className}`}>
            <InputLabel id="dropdown-control" shrink sx={{ backgroundColor: 'white' }}>
                {label}
            </InputLabel>
            <Select
                labelId="dropdown-control"
                id="dropdown-control"
                label={label}
                value={controlValue}
                onChange={onSelectionChange}>
                {dorpdownItems}
            </Select>
        </FormControl>
    );
};
