import React, { useMemo, useReducer } from 'react';
import {
    AppLoadingMessage,
    AppLoadingState,
    AppLoadingAction,
    AppLoadingActionType,
    ShowAppLoadingAction,
    HideAppLoadingAction,
    appLoadingStateReducer,
} from './app-loading-context-provider';

type AppLoadingActionsApi = {
    showLoading: (message: AppLoadingMessage) => void;
    hideLoading: () => void;
};

export type FormContextType = {
    state: AppLoadingState;
    dispatch: (action: AppLoadingAction) => void;
};

export const AppLoadingContext = React.createContext<FormContextType>({} as FormContextType);
export const AppLoadingApiContext = React.createContext<AppLoadingActionsApi>({} as AppLoadingActionsApi);

export const AppLoadingContextProvider = ({
    initialState,
    children,
}: {
    initialState: AppLoadingState;
    children?: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(appLoadingStateReducer, initialState);

    const LoadingApi = useMemo(() => {
        const showLoading = (message: AppLoadingMessage) => {
            const action: ShowAppLoadingAction = {
                type: AppLoadingActionType.SHOW,
                payload: {
                    message,
                },
            };
            dispatch(action);
        };

        const hideLoading = () => {
            const action: HideAppLoadingAction = {
                type: AppLoadingActionType.HIDE,
            };
            dispatch(action);
        };

        return {
            showLoading,
            hideLoading,
        } as const;
    }, []);

    return (
        <AppLoadingApiContext.Provider value={LoadingApi}>
            <AppLoadingContext.Provider
                value={{
                    state,
                    dispatch,
                }}>
                {children}
            </AppLoadingContext.Provider>
        </AppLoadingApiContext.Provider>
    );
};

export * from './app-loading-state-reducer';
export * from './app-loading-state';
export * from './app-loading-action';
