export type FormFieldPageBreakConfig = {
    variable: string;
    title: string;
    hideBack: boolean;
    hideNext: boolean;
    hideScan: boolean;
    hideHome: boolean;
    hideReturn: boolean;
    hidePageNumbers: boolean;
    showHelp: boolean;
    helpText?: string;
};

export const defaultFormFieldPageBreakConfig: FormFieldPageBreakConfig = {
    variable: '',
    title: '',
    hideBack: false,
    hideNext: false,
    hideScan: false,
    hideHome: false,
    hideReturn: false,
    hidePageNumbers: false,
    showHelp: false,
    helpText: '',
};
