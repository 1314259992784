import React from 'react';

type CustomDialogActionsProps = {
    children: React.ReactNode;
    className?: string;
};

export const CustomDialogActions = ({ children, className = '' }: CustomDialogActionsProps) => {
    return (
        <div
            className={`flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b ${className}`}>
            {children}
        </div>
    );
};
