import { i18n } from 'i18n';

export type FormFieldDropdownOption = {
    id: string;
    label: string;
    value: string;
    show: boolean;
    isDefault: boolean;
};

export type FormFieldDropdownConfig = {
    variable: string;
    title: string;
    defaultValue: string;
    required: boolean;
    readonly: boolean;
    multiSelect: boolean;
    buttonsList: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText?: string;
    options: FormFieldDropdownOption[];
};

export const defaultFormFieldDropdownConfig: FormFieldDropdownConfig = {
    variable: '',
    title: i18n.t('Drop-Down field friendly name goes here'),
    defaultValue: '',
    required: false,
    readonly: false,
    multiSelect: false,
    buttonsList: false,
    excludeFromMetadata: false,
    showHelp: false,
    helpText: '',
    options: [],
};
