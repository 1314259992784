import produce from 'immer';
import { AppSnackbarAction, AppSnackbarActionType, AppSnackbarState } from './';

export const appSnackbarStateReducer = produce((state: AppSnackbarState, action: AppSnackbarAction) => {
    switch (action.type) {
        case AppSnackbarActionType.SHOW: {
            state.message.title = action.payload.message.title;
            state.message.text = action.payload.message.text;
            state.messageType = action.payload.type;
            state.show = true;
            return state;
        }
        case AppSnackbarActionType.HIDE: {
            state.show = false;
            return state;
        }
        default:
            return state;
    }
});
