import { useRef } from 'react';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldPageBreakConfig, defaultFormFieldPageBreakConfig } from './form-field-pagebreak-config';

export const useFormFieldPageBreak = ({ field, updateField }: FormFieldComponentProps) => {
    const configFromField = field.config as FormFieldPageBreakConfig;
    const config = {
        ...defaultFormFieldPageBreakConfig,
        ...configFromField,
    };
    if (field.pageNumber === 1) config.hideBack = true;
    const configRef = useRef<FormFieldPageBreakConfig>(config);

    const onCustomTextFieldChange = (variable: string, value: string) => {
        updateConfig(variable as keyof FormFieldPageBreakConfig, value);
    };

    const onCustomCheckboxChange = (variable: string, value: boolean) => {
        updateConfig(variable as keyof FormFieldPageBreakConfig, value);
    };

    const updateConfig = <K extends keyof FormFieldPageBreakConfig>(key: K, value: FormFieldPageBreakConfig[K]) => {
        const updatedConfig = {
            ...configRef.current,
        };
        updatedConfig[key] = value;
        configRef.current = updatedConfig;

        const modifiedField = {
            ...field,
        };
        modifiedField.config = updatedConfig;
        updateField(modifiedField);
    };

    return {
        config,
        pageNumber: field.pageNumber,
        totalPages: field.totalPages,
        onCustomTextFieldChange,
        onCustomCheckboxChange,
    } as const;
};
