import { MouseEvent } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Paper } from '@mui/material';
import { FormErrorsPanel } from 'components/form-errors-panel';
import { FormEmptyStage } from './form-empty-stage';
import { SortableFormFieldPreview } from './sortable-form-field-preview';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { useFormFields } from 'custom-hooks/use-form-fields';

export const FormCanvasPanel = () => {
    const fields = useFormFields();
    const { selectField } = useFormActions();
    const { setNodeRef } = useDroppable({
        id: 'form_canvas_droppable',
        data: {
            parent: null,
            isContainer: true,
        },
    });

    return (
        <div className="flex flex-1 flex-col">
            <Paper
                id="form-canvas"
                ref={setNodeRef}
                elevation={3}
                className="flex-1 ml-4 mr-4 mt-0 pl-8 pr-8 pb-8 pt-0 overflow-y-auto"
                onClick={(e: MouseEvent) => {
                    if (e.currentTarget !== e.target) return;
                    selectField(null);
                }}>
                <div className="flex-col">
                    {fields.map((field, index) => (
                        <SortableFormFieldPreview
                            key={field.id}
                            id={field.id}
                            field={field}
                            index={index}
                            disabled={false}
                        />
                    ))}
                    {fields.length < 2 && <FormEmptyStage />}
                </div>
            </Paper>

            <FormErrorsPanel />
        </div>
    );
};

export * from './form-empty-stage';
export * from './form-field-preview';
export * from './sortable-form-field-preview';
