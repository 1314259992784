import { FormState } from 'contracts/form-state';
import { i18n } from 'i18n';

export const INITIAL_FORM_STATE: FormState = {
    loading: false,
    errors: { show: false, groups: [] },
    selectedField: null,
    targetMfpId: 'mfp_800x480',
    sampleFormId: 'blank',
    showNewForm: true,
    form: {
        title: i18n.t('Form Name'),
        valid: false,
        fields: [],
    },
};
