import Zoom from '@mui/material/Zoom';
import { IconButton, Tooltip } from '@mui/material';

export type CustomTooltipPosition =
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start';

type CustomIconButtonProps = {
    src?: string;
    srcHover?: string;
    tooltip?: string;
    tooltipPlacement?: CustomTooltipPosition;
    lightRipple?: boolean;
    className?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const CustomIconButton = ({
    src,
    srcHover = '',
    tooltip = '',
    tooltipPlacement = 'top',
    lightRipple = false,
    className = '',
    children,
    onClick,
}: CustomIconButtonProps) => {
    const onIconButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        if (onClick) onClick(event);
    };

    let contents = children;
    if (!contents)
        contents = (
            <img
                src={src}
                alt={tooltip}
                onMouseOver={(e): void => {
                    srcHover && (e.currentTarget.src = srcHover);
                }}
                onMouseOut={(e): void => {
                    srcHover && (e.currentTarget.src = src || '');
                }}
            />
        );

    return (
        <Tooltip title={tooltip} placement={tooltipPlacement} TransitionComponent={Zoom} arrow>
            <IconButton
                className={`${className}`}
                aria-label={tooltip}
                component="label"
                onClick={onIconButtonClick}
                sx={() => ({
                    '& .MuiTouchRipple-child': {
                        backgroundColor: `${lightRipple ? '#fff' : '#666'} !important`,
                    },
                })}>
                {contents}
            </IconButton>
        </Tooltip>
    );
};
