import { LinearProgress, Typography } from '@mui/material';
import { CustomDialog, CustomDialogActions, CustomDialogBody, CustomDialogTitle } from 'components/custom-controls';
import { useAppLoading } from 'custom-hooks/use-app-loading';

export const AppLoading = () => {
    const { state } = useAppLoading();

    return (
        <CustomDialog show={state.show}>
            <CustomDialogTitle>
                <Typography variant="h6" className="text-base pl-2">
                    {state.message.title}
                </Typography>
            </CustomDialogTitle>
            <CustomDialogBody>
                <div className="min-w-[350px]">
                    <LinearProgress color="secondary" />
                </div>
            </CustomDialogBody>
            <CustomDialogActions>
                <div className="flex-1 pl-1">
                    <Typography variant="caption">{state.message.text}</Typography>
                </div>
            </CustomDialogActions>
        </CustomDialog>
    );
};
