import { i18n } from 'i18n';

export type FormFieldTextAreaConfig = {
    variable: string;
    title: string;
    defaultValue: string;
    minLength: string;
    maxLength: string;
    required: boolean;
    readonly: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText?: string;
};

export const defaultFormFieldTextAreaConfig: FormFieldTextAreaConfig = {
    variable: '',
    title: i18n.t('Text area field friendly name goes here'),
    defaultValue: '',
    minLength: '0',
    maxLength: '5000',
    required: false,
    readonly: false,
    excludeFromMetadata: false,
    showHelp: false,
    helpText: '',
};
