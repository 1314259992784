import { useRef } from 'react';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldHiddenConfig, defaultFormFieldHiddenConfig } from './form-field-hidden-config';

export const useFormFieldHidden = ({ field, updateField }: FormFieldComponentProps) => {
    const configFromField = field.config as FormFieldHiddenConfig;
    const config = {
        ...defaultFormFieldHiddenConfig,
        ...configFromField,
    };
    const configRef = useRef<FormFieldHiddenConfig>(config);

    const onCustomTextFieldChange = (variable: string, value: string) => {
        updateConfig(variable as keyof FormFieldHiddenConfig, value);
    };

    const onCustomCheckboxChange = (variable: string, value: boolean) => {
        updateConfig(variable as keyof FormFieldHiddenConfig, value);
    };

    const updateConfig = <K extends keyof FormFieldHiddenConfig>(key: K, value: FormFieldHiddenConfig[K]) => {
        const updatedConfig = {
            ...configRef.current,
        };
        updatedConfig[key] = value;
        configRef.current = updatedConfig;

        const modifiedField = {
            ...field,
        };
        modifiedField.config = updatedConfig;
        updateField(modifiedField);
    };

    return {
        config,
        onCustomTextFieldChange,
        onCustomCheckboxChange,
    } as const;
};
