import { i18n } from 'i18n';

export type FormFieldTextConfig = {
    variable: string;
    title: string;
    defaultValue: string;
    minLength: string;
    maxLength: string;
    required: boolean;
    readonly: boolean;
    mask: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText?: string;
};

export const defaultFormFieldTextConfig: FormFieldTextConfig = {
    variable: '',
    title: i18n.t('Text line field friendly name goes here'),
    defaultValue: '',
    minLength: '0',
    maxLength: '1000',
    required: false,
    readonly: false,
    mask: false,
    excludeFromMetadata: false,
    showHelp: false,
    helpText: '',
};
