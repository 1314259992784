import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CustomHelpIcon } from 'components/custom-controls';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldDropdownConfig } from './form-field-dropdown-config';
import { useTranslation } from 'react-i18next';

export const FormFieldDropdownPreview = ({ field }: FormFieldComponentProps) => {
    const config = field.config as FormFieldDropdownConfig;
    const { t } = useTranslation();

    if (config.buttonsList) {
        return (
            <div className="flex flex-col">
                <div className="break-all">{`${config.title} ${config.required ? '*' : ''}`}</div>
                <div className="flex flex-row">
                    <div className="flex-1 flex-wrap">
                        {config.options
                            .filter((opt) => opt.show)
                            .map((opt) => (
                                <Button
                                    key={opt.id}
                                    className={`mt-4 mb-2 ml-4 mr-2 min-h-[56px] h-max-[80px] w-48 break-all ${
                                        opt.isDefault ? 'border-4 border-solid border-yellow-100' : ''
                                    }`}
                                    variant="contained">
                                    {opt.label}
                                </Button>
                            ))}
                    </div>
                    {config.showHelp && <CustomHelpIcon className="ml-3" />}
                </div>
            </div>
        );
    }

    const filteredOptions = config.options.filter((opt) => opt.show && opt.isDefault);
    const defaultValue =
        filteredOptions && filteredOptions.length > 0
            ? filteredOptions[0].value || filteredOptions[0].label || 'default'
            : null;
    const dorpdownItems = filteredOptions.map((opt) => (
        <MenuItem value={opt.value || opt.label || 'default'} key={opt.id}>
            <div className="break-all whitespace-pre-wrap overflow-hidden max-h-[75px]">{opt.label}</div>
        </MenuItem>
    ));

    return (
        <div className="flex flex-row">
            <FormControl sx={{ m: 0, minWidth: 120 }} size="small" className="flex-1">
                <InputLabel id="dropdown-control" shrink sx={{ backgroundColor: 'white' }}>
                    {`${config.title} ${config.required ? '*' : ''}`}
                </InputLabel>
                {defaultValue && (
                    <Select labelId="dropdown-control" id="dropdown-control" label={config.title} value={defaultValue}>
                        {dorpdownItems}
                    </Select>
                )}
                {!defaultValue && (
                    <Select labelId="dropdown-control" id="dropdown-control" label={config.title}>
                        <MenuItem>{t('None')}</MenuItem>
                    </Select>
                )}
            </FormControl>
            {config.showHelp && <CustomHelpIcon className="ml-3" />}
        </div>
    );
};
