import { CustomIcon } from 'components/custom-controls/custom-controls';
import { memo } from 'react';

type FormFieldIconProps = {
    icon: string;
    title: string;
    dropState?: boolean;
};

const FormFieldIconBase = ({ icon, title, dropState }: FormFieldIconProps) => {
    const border = dropState ? 'drop-shadow-lg' : 'border border-[#eeeeee]';

    return (
        <div className="flex flex-col items-center w-14 mr-1 mb-1 group">
            <div
                className={`flex items-center justify-center w-14 h-10 border-solid ${border} rounded pl-3 pr-3 pt-2 pb-2 mb-7 group-hover:border-stc-800 bg-white`}>
                <CustomIcon src={icon} />
            </div>
            <div className="bg-stc-800 text-white text-sm pl-2 pr-2 pt-0.5 pb-0.5 mt-11 opacity-0 rounded fixed z-11 group-hover:opacity-100 pointer-events-none whitespace-nowrap">
                {title}
            </div>
        </div>
    );
};

export const FormFieldIcon = memo(FormFieldIconBase);
