import React, { useMemo, useReducer } from 'react';
import {
    DeleteFormFieldAction,
    FormActionType,
    FormContextType,
    FormState,
    FormErrors,
    InsertFormFieldAction,
    MoveFormFieldAction,
    NewFormAction,
    NewFormOptions,
    ReplaceFormFieldAction,
    SelectFormFieldAction,
    SetFormErrorsAction,
    SetShowFormErrorsAction,
    SwapFormFieldsAction,
    UpdateFormFieldAction,
    RemoveDropZonesAction,
    SetFormTitleAction,
    ResetPageNumbersAction,
    SampleFormOptions,
    SampleFormAction,
} from 'contracts/form-state';
import { FormModel, FormField } from 'contracts/forms';
import { formStateReducer } from './';

type FormActionsApi = {
    insertField: (field: FormField, index: number) => void;
    updateField: (field: FormField) => void;
    moveField: (id: string, index: number) => void;
    deleteField: (id: string) => void;
    removeDropZones: () => void;
    replaceField: (index: number, field: FormField, swapIndex?: number) => void;
    resetPageNumbers: () => void;
    swapFields: (from: number, to: number) => void;
    selectField: (id: string | null) => void;
    createNewForm: (options: NewFormOptions) => void;
    createSampleForm: (options: SampleFormOptions) => void;
    setFormErrors: (errors: FormErrors) => void;
    setShowFormErrors: (show: boolean) => void;
    setFormTitle: (id: string) => void;
};

export const FormContext = React.createContext<FormContextType>({} as FormContextType);
export const FormActionsApiContext = React.createContext<FormActionsApi>({} as FormActionsApi);
export const FormFieldsContext = React.createContext<FormModel['fields']>({} as FormModel['fields']);
export const FormTitleContext = React.createContext<FormModel['title']>({} as FormModel['title']);
export const FormShowNewDialogContext = React.createContext<FormState['showNewForm']>({} as FormState['showNewForm']);
export const FormSelectedFieldContext = React.createContext<FormState['selectedField']>(
    {} as FormState['selectedField'],
);
export const FormErrorsContext = React.createContext<FormState['errors']>({
    show: false,
    groups: [],
} as FormState['errors']);

export const FormContextProvider = ({
    initialFormState,
    children,
}: {
    initialFormState: FormState;
    children?: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(formStateReducer, initialFormState);

    const formAPi = useMemo(() => {
        const insertField = (field: FormField, index: number) => {
            const action: InsertFormFieldAction = {
                type: FormActionType.INSERT_FIELD,
                payload: {
                    index,
                    field,
                },
            };
            dispatch(action);
        };

        const updateField = (field: FormField) => {
            const action: UpdateFormFieldAction = {
                type: FormActionType.UPDATE_FIELD,
                payload: {
                    field,
                },
            };
            dispatch(action);
        };

        const moveField = (id: string, index: number) => {
            const action: MoveFormFieldAction = {
                type: FormActionType.MOVE_FIELD,
                payload: {
                    id,
                    index,
                },
            };
            dispatch(action);
        };

        const deleteField = (id: string) => {
            const action: DeleteFormFieldAction = {
                type: FormActionType.DELETE_FIELD,
                payload: {
                    id,
                },
            };
            dispatch(action);
        };

        const removeDropZones = () => {
            const action: RemoveDropZonesAction = {
                type: FormActionType.REMOVE_DROP_ZONES,
            };
            dispatch(action);
        };

        const replaceField = (index: number, field: FormField, swapIndex?: number) => {
            const action: ReplaceFormFieldAction = {
                type: FormActionType.REPLACE_FIELD,
                payload: {
                    index,
                    field,
                    swapIndex,
                },
            };
            dispatch(action);
        };

        const resetPageNumbers = () => {
            const action: ResetPageNumbersAction = {
                type: FormActionType.RESET_PAGE_NUMBERS,
            };
            dispatch(action);
        };

        const swapFields = (from: number, to: number) => {
            const action: SwapFormFieldsAction = {
                type: FormActionType.SWAP_FIELDS,
                payload: {
                    from,
                    to,
                },
            };
            dispatch(action);
        };

        const selectField = (id: string | null) => {
            const action: SelectFormFieldAction = {
                type: FormActionType.SELECT_FIELD,
                payload: {
                    id,
                },
            };
            if (!id) {
                dispatch(action);
                return;
            }
            selectField(null);
            setTimeout(() => dispatch(action), 50);
        };

        const createNewForm = (payload: NewFormOptions) => {
            const action: NewFormAction = {
                type: FormActionType.NEW_FORM,
                payload,
            };
            dispatch(action);
        };

        const createSampleForm = (payload: SampleFormOptions) => {
            const action: SampleFormAction = {
                type: FormActionType.SAMPLE_FORM,
                payload,
            };
            dispatch(action);
        };

        const setFormTitle = (payload: string) => {
            const action: SetFormTitleAction = {
                type: FormActionType.SET_TITLE,
                payload,
            };
            dispatch(action);
        };

        const setFormErrors = (payload: FormErrors) => {
            const action: SetFormErrorsAction = {
                type: FormActionType.SET_ERRORS,
                payload,
            };
            dispatch(action);
        };

        const setShowFormErrors = (payload: boolean) => {
            const action: SetShowFormErrorsAction = {
                type: FormActionType.SET_SHOW_ERRORS,
                payload,
            };
            dispatch(action);
        };

        return {
            insertField,
            updateField,
            moveField,
            deleteField,
            removeDropZones,
            replaceField,
            resetPageNumbers,
            swapFields,
            selectField,
            createNewForm,
            createSampleForm,
            setFormErrors,
            setShowFormErrors,
            setFormTitle,
        } as const;
    }, []);

    return (
        <FormActionsApiContext.Provider value={formAPi}>
            <FormFieldsContext.Provider value={state.form.fields}>
                <FormTitleContext.Provider value={state.form.title}>
                    <FormShowNewDialogContext.Provider value={state.showNewForm}>
                        <FormSelectedFieldContext.Provider value={state.selectedField}>
                            <FormErrorsContext.Provider value={state.errors}>
                                <FormContext.Provider
                                    value={{
                                        state,
                                        dispatch,
                                    }}>
                                    {children}
                                </FormContext.Provider>
                            </FormErrorsContext.Provider>
                        </FormSelectedFieldContext.Provider>
                    </FormShowNewDialogContext.Provider>
                </FormTitleContext.Provider>
            </FormFieldsContext.Provider>
        </FormActionsApiContext.Provider>
    );
};

export * from './form-state-reducer';
export * from './form-constants';
