import { AppLoadingContext } from 'context-providers/app-loading-context-provider';
import { useContext } from 'react';

export const useAppLoading = () => {
    const context = useContext(AppLoadingContext);

    if (!context) {
        throw new Error('useAppLoading has to be used within <AppLoadingContext.Provider>');
    }

    return context;
};
