import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AppInstructions = () => {
    const { t } = useTranslation();
    return (
        <div className="flex pl-4 pr-4 pt-4">
            <Typography variant="caption">
                {t('Create forms by dragging fields into the working area and editing settings.')}
            </Typography>
        </div>
    );
};
